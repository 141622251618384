<template>
  <ol>
    <ToolbarAction label="Close" icon="times" @action="$router.push('/dashboard')"/>
  </ol>
</template>

<script>
import ToolbarAction from '@/components/ToolBarAction'

export default {
  name: 'UserSettingsTools',
  components: { ToolbarAction }
}
</script>

<style lang="stylus" scoped>
ol
  display flex
  flex-direction row
  justify-content center
  gap 15px
</style>