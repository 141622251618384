<template>
  <li class="entry-tile" :class="{ selected: entry.selected }" :data-entry-id="entry.id"
      @click.exact="goToEntry">
    <div class="menu">
      <span class="title">{{ entry.title }}</span>
    </div>
    <img :src="`${repositoryUrl}/${entry.boardId}/${entry.thumbRepoKey}`"
         :width="entry.thumb.width" :height="entry.thumb.height"/>
  </li>
</template>

<script>
export default {
  name: 'PublicEntryTile',
  props: ['entry'],
  computed: {
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL
  },
  methods: {
    goToEntry() {
      this.$store.commit('publicBoard/CURRENT_ENTRY', this.entry.id)
    }
  }
}
</script>

<style lang="stylus" scoped>
.entry-tile
  position relative
  display block
  padding 5px
  min-width 312px
  background white
  border 1px solid #ccc
  border-radius 5px
  box-shadow 0 0 8px rgba(0, 0, 0, 0.15)
  user-select none
  cursor pointer

  .menu
    display none
    position absolute
    padding 10px
    top 5px
    left 5px
    right 5px
    background rgba(255, 255, 255, .9)
    align-items flex-start
    gap 10px

    span
      flex 1
      font-weight 700
      font-size .9em
      display -webkit-box
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      overflow hidden

  &:hover
    .menu
      display flex

    .tile-options
      display block

  img
    display block
    max-width 300px
    margin 0 auto
    pointer-events none

</style>