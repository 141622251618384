<template>
  <div id="pricing">
    <Toggle v-if="plans.length > 1" v-model="payAnnually"/>
    <ol>
      <Plan v-for="plan in plans" :key="plan.label" :plan="plan" @choose="choose"
            :payAnnually="payAnnually" :checkoutStarted="checkoutStarted"/>
    </ol>
  </div>
</template>

<script>
import Toggle from '@/components/form/Toggle'
import Plan from '@/components/userSettings/plans/Plan'
import http from '@/http'
import { mapState } from 'vuex'
import { loadStripe } from '@stripe/stripe-js/pure'

export default {
  name: 'Payment',
  components: { Toggle, Plan },
  data() {
    return {
      payAnnually: true,
      checkoutStarted: false
    }
  },
  computed: mapState({ plans: s => s.plans.plans }),
  methods: {
    choose(plan) {

      this.checkoutStarted = true

      loadStripe(process.env.VUE_APP_STRIPE_KEY)
          .then(stripe => {
            http.post('/stripe/session', { plan })
                .then(session => stripe.redirectToCheckout({ sessionId: session.data.id }))
                .catch(error => console.error('Error:', error))
          })
          .catch(() => {
            console.error('Error loading Stripe')
            this.checkoutStarted = false
          })

    }
  },
  mounted() {
    this.$store.dispatch('plans/fetchPlans')
  }
}
</script>

<style lang="stylus" scoped>
#pricing
  display flex
  flex-direction column
  align-items center
  gap 30px

  ol
    display flex
    gap 40px
</style>