import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import { faCaretDown, faCaretRight, faCheck as fasCheck } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import {
    faBallotCheck,
    faBell,
    faBellOn,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faCog,
    faCopy,
    faDownload,
    faEllipsisVAlt,
    faExclamation,
    faHeart,
    faHome,
    faImage,
    faPencilAlt,
    faPlus,
    faQuestionCircle,
    faRandom,
    faSearch,
    faShareSquare,
    faThumbsUp,
    faTimes,
    faTrash,
    faUsers,
    faUserSecret
} from '@fortawesome/pro-duotone-svg-icons'

export default function loadFA() {
    library.add(
        faCaretRight,
        faCaretDown,
        fasCheck,
        farHeart,
        faBallotCheck,
        faBell,
        faBellOn,
        faCheck,
        faCheckCircle,
        faCheckDouble,
        faChevronDoubleLeft,
        faChevronDoubleRight,
        faCog,
        faCopy,
        faDownload,
        faEllipsisVAlt,
        faExclamation,
        faHeart,
        faHome,
        faImage,
        faPencilAlt,
        faPlus,
        faQuestionCircle,
        faRandom,
        faSearch,
        faShareSquare,
        faThumbsUp,
        faTimes,
        faTrash,
        faUsers,
        faUserSecret
    )
    Vue.component('fa', FontAwesomeIcon)
}