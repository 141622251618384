<template>
  <modal title="Delete Board" class="modal-delete" icon="trash">
    You are about to delete {{ selectedBoards.length }} board{{ selectedBoards.length > 1 ? 's' : '' }}.
    <button class="alert" @click.prevent="go">Delete</button>
  </modal>
</template>

<script>
import Modal from '../Modal'
import { mapGetters } from 'vuex'

export default {
  name: 'DeleteBoardModal',
  components: { Modal },
  computed: mapGetters('boards', ['selectedBoards']),
  methods: {
    go() {
      this.$store.dispatch('boards/deleteBoards', this.selectedBoards.map(b => b.id))
          .then(() => this.$store.commit('DISMISS_MODAL'))
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-delete
  .modal-body
    display flex
    flex-direction column

  button
    margin-top 20px
</style>