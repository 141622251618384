<template>
  <ol class="dashboard-tools">

    <ToolbarAction :label="boards.length !== selectedBoards.length ? 'Select All' : 'Unselect All'"
                   v-show="boards.length > 0"
                   icon="check-double" @action="selectAllBoards"/>

    <ToolbarAction label="Favorite" icon="heart" @action="toggleStarMultiple"
                   v-show="selectedBoards.length > 0"/>

    <ToolbarAction label="Rename" icon="pencil-alt" @action="$router.push(`/board/${selectedBoards[0].id}/settings`)"
                   v-show="selectedBoards.length === 1"/>

    <ToolbarAction label="Share" icon="share-square" @action="share"
                   v-show="selectedBoards.length === 1"/>

    <ToolbarAction label="Delete" icon="trash" @action="deleteBoard"
                   v-show="selectedBoards.length > 0"/>

    <li>
      <button @click="addBoard">Create Board</button>
    </li>

  </ol>
</template>

<script>
import ToolbarAction from '@/components/ToolBarAction'
import NewBoardModal from '@/components/modals/NewBoardModal'
import DeleteBoardModal from '@/components/modals/DeleteBoardModal'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'DashboardTools',
  components: { ToolbarAction },
  data: () => {
    return {
      showModal: false,
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState({ boards: s => s.boards.boards }),
    ...mapGetters('boards', ['selectedBoards'])
  },
  methods: {
    addBoard() {
      this.$store.commit('SHOW_MODAL', { modal: NewBoardModal })
    },
    share() {
      this.$router.push(`/board/${this.selectedBoards[0].id}/settings#sharing`)
    },
    deleteBoard() {
      this.$store.commit('SHOW_MODAL', { modal: DeleteBoardModal })
    },
    ...mapActions('boards', ['selectAllBoards', 'toggleStarMultiple'])
  }
}
</script>

<style lang="stylus" scoped>
.dashboard-tools
  display flex
  flex-direction row
  justify-content center
  /*align-items center*/
  gap 15px
</style>