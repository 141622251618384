var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{ref:"main"},[_c('section',{staticClass:"exclude"},[_vm._v(" Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('section',[_c('BoardCheckbox',{attrs:{"label":"Click here"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('fieldset',[_c('label',[_vm._v("Text")]),_c('input',{attrs:{"type":"text","placeholder":"Some text"}})]),_c('fieldset',[_c('label',[_vm._v("Password")]),_c('input',{attrs:{"type":"password","placeholder":"Some text"}})]),_c('fieldset',[_c('label',[_vm._v("Email (wide)")]),_c('input',{staticClass:"wide",attrs:{"type":"email","placeholder":"Some text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('label',[_vm._v("Textarea")]),_c('textarea',{attrs:{"placeholder":"Some text","rows":"5"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('label',[_vm._v("Button")]),_c('button',[_vm._v("Click me")]),_c('br'),_c('button',{staticClass:"loading"},[_vm._v("Click me")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('button',{staticClass:"small"},[_vm._v("Save")]),_c('br'),_c('button',{staticClass:"small alert"},[_vm._v("Save")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('label',[_vm._v("A select input field")]),_c('select',[_c('option',[_vm._v("Option 1")]),_c('option',[_vm._v("Option 2")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('form',[_c('label',[_vm._v("Pick one")]),_c('select',[_c('option',[_vm._v("Option 1")]),_c('option',[_vm._v("Option 2")])]),_c('label',{attrs:{"for":"asd"}},[_vm._v("Pick one")]),_c('input',{attrs:{"type":"text","id":"asd"}})])])
}]

export { render, staticRenderFns }