import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import http from './http'
import loadFA from './fa'
import { Settings } from 'luxon'

globalHelperConfig()
loadFA()

if (doesNotRequireLogin())
    loadApp()
else if (isLoggedIn()) {
    registerDynamicComponents()
    configureHttp()
    store.dispatch('loadUserInfo').then(loadApp)
} else {
    localStorage.setItem('authTarget', window.location.href)
    window.location.href = '/login'
}

function doesNotRequireLogin() {
    return ['/login', '/signin', '/register', '/signup', '/invitation', '/forgot', '/reset', '/public', '/appsumo', '/styleguide']
        .find(path => window.location.pathname.startsWith(path))
}

function isLoggedIn() {
    return localStorage.getItem('jwt') !== null
}

function loadApp() {
    new Vue({
        name: 'Boardflow',
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
}

function registerDynamicComponents() {

    let components = require.context('./components/modals', false, /\.vue$/)
    components.keys().forEach(file => {
        const config = components(file).default
        Vue.component(config.name, config)
    })

    components = require.context('./components/header/notifications', false, /\.vue$/)
    components.keys().forEach(file => {
        const config = components(file).default
        Vue.component(config.name, config)
    })

    window.addEventListener('beforeunload', function(e) {
        if (store.state.uploads.runningUploads > 0) {
            e.preventDefault()
            e.returnValue = 'You have ongoing uploads. By leaving the page the upload process will be interrupted.'
        }
    })
}

function configureHttp() {

    http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')

    http.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('jwt')
                localStorage.setItem('authTarget', window.location.href)
                window.location.href = '/login'
            } else {
                console.error(error)
                return Promise.reject(error)
            }
        }
    )

}

function globalHelperConfig() {

    Settings.defaultLocale = 'en' // Luxon default locale

    let dev = process.env.NODE_ENV === 'development'

    Vue.config.productionTip = dev

    if (dev) {
        Vue.mixin({
            created() {
                console.log('[created] ' + this.$options.name)
            },
            mounted() {
                console.log('[mounted] ' + this.$options.name)
            }
            // updated() {
            //     console.log('[updated] ' + this.$options.name)
            // }
            // destroyed() {
            //     console.log('[destroyed] ' + this.$options.name)
            // }
        })
    }

}