<template>
  <div class="description">
    <span v-if="!editing" @click="click" :class="{empty: !entry.description}">{{ entry.description || 'Edit description' }}</span>
    <textarea v-else :value="entry.description" placeholder="Edit description"
              @keydown.ctrl.enter="enter" ref="input"/>
    <button v-if="editing" class="grow" @click="enter">Save</button>
  </div>
</template>

<script>
export default {
  name: 'EditableDescription',
  props: ['entry'],
  data() {
    return { editing: false }
  },
  methods: {
    click() {
      this.editing = true
      this.$nextTick(() => this.$refs.input.select())
    },
    enter() {
      this.$store.dispatch('entries/editEntry', { description: this.$refs.input.value }).then(() => this.editing = false)
    },
    esc() {
      this.editing = false
    }
  },
  watch: {
    entry: function() {
      this.editing = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.description
  padding 10px 0
  border-bottom 1px solid #ddd

  button
    margin-top 10px

  span
    display block
    padding 5px
    border-radius 5px
    cursor pointer
    white-space: pre-line

    &.empty
      font-style italic
      color #aaa

    &:hover
      background-color whitesmoke

  textarea
    border-width 1px
    padding 4px
    width 100%
    height 6em

    &::placeholder
      font-style italic
</style>