import AppLayout from '@/layouts/AppLayout'
import Search from '@/views/Search'
import UserSettingsTools from '@/components/userSettings/UserSettingsTools'
import store from '@/store'

export default [
    {
        path: '/search', name: 'Search', component: Search,
        meta: { layout: AppLayout, toolbar: UserSettingsTools },
        beforeEnter: (to, from, next) => {
            if (!to.query.q) // `q` is the querystring param with the search term, eg https://boardflow.co/search?q=flat
                next('/dashboard')
            else
                store.dispatch('search/searchRemote', to.query.q).then(() => {
                    store.commit('boards/CURRENT_BOARD', null)
                    store.commit('entries/CURRENT_ENTRY', null)
                    store.commit('search/RESET_SEARCH')
                    next()
                })
        }
    }
]