<template>
  <div class="entry-details">
    <div class="entry-source">Source:
      <a v-if="entry.url && entry.url.startsWith('http')" :href="entry.url" target="_blank">{{ entry.url }}</a>
      <span v-else>Local upload</span>
    </div>
    <div class="entry-type">File type:
      <span>{{ entry.type }}</span>
    </div>
    <div v-if="entry.type !== 'SVG'" class="entry-type">Size:
      <span>{{ entry.dimension.width }} x {{ entry.dimension.height }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntryDetails',
  props: ['entry']
}
</script>

<style lang="stylus" scoped>
.entry-details
  padding 10px 5px
  font-size .8em
  border-bottom 1px solid #ddd

  a
    word-break break-all
</style>