<template>
  <div id="single-board">
    <div id="empty-board" v-if="entries.length === 0">This board is still empty. Drag files or folders here, or
      <a href="https://chrome.google.com/webstore/detail/boardflow/gbecpkkgknmpkedafcelmbcfhmhghafh"
         class="extension-nudge" target="_blank">get our browser extension</a></div>
    <EntryList :entries="entries"/>
  </div>
</template>

<script>
import EntryList from '@/components/singleBoard/EntryList'
import store from '@/store'
import { mapState } from 'vuex'

const prepareView = (to, from, next) => {
  store.dispatch('entries/fetchEntries', to.params.id).then(() => {
    store.commit('boards/CURRENT_BOARD', to.params.id)
    store.commit('entries/CURRENT_ENTRY', null)
    next()
  })
}

export default {
  name: 'SignleBoard',
  beforeRouteEnter: prepareView,
  beforeRouteUpdate: prepareView,
  components: { EntryList },
  computed: mapState({ entries: s => s.entries.entries })
}
</script>

<style lang="stylus" scoped>
#single-board
  height 100%
  max-height 100%
  overflow-y scroll

#empty-board
  padding 50px
  text-align center
</style>