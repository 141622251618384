<template>
  <div class="by">
<!--    <img :src="`https://gravatar.com/avatar/${this.entry.user.md5}`" width="33" height="38"/>-->
    <span>Saved by <strong>{{ entry.user.fullName }}</strong><br/>
      on {{ formatted }} ({{ relative }})</span>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'SavedBy',
  props: ['entry'],
  data() {
    return {
      hash: ''
    }
  },
  computed: {
    formatted() {
      return DateTime.fromMillis(this.entry.ts).toLocaleString(DateTime.DATETIME_MED)
    },
    relative() {
      return DateTime.fromMillis(this.entry.ts).toRelative()
    }
  }
}
</script>

<style lang="stylus" scoped>
.by
  display flex
  flex-direction row
  padding-top 10px
  font-size .8em

  img
    vertical-align top
    width 38px
    height 38px
    border-radius 3px
    margin-right 10px
</style>