<template>
  <form @submit.prevent="submit" class="signin-form">
    <ol class="mode-selector">
      <li class="active">Reset your password</li>
    </ol>
    <div v-show="!done" class="fields">
      <fieldset>
        <label>New password</label>
        <input type="password" class="wide" v-model.trim="newPassword" required autofocus ref="pwd"/>
      </fieldset>

      <ResultLabel v-show="error" class="error">There was a problem resetting your password</ResultLabel>

      <button class="grow" type="submit" ref="button">Reset password</button>
    </div>
    <div v-show="done" class="fields">
      <span v-show="message">{{ message }}</span>
      <button class="grow" type="submit" ref="button" @click="$router.replace('/login')">Go to Login</button>
    </div>
  </form>
</template>

<script>
import http from '../http'
import ResultLabel from '@/components/auth/ResultLabel'

export default {
  name: 'ResetPassword',
  components: { ResultLabel },
  data() {
    return {
      newPassword: undefined,
      error: false,
      message: '',
      done: false
    }
  },
  methods: {
    buttonState(enabled) {
      this.$refs.button.classList[enabled ? 'remove' : 'add']('loading')
      this.$refs.button.disabled = !enabled
    },
    submit() {
      this.buttonState(false)
      http.post('/auth/password/reset', {
        newPassword: this.newPassword,
        code: this.$route.params.code
      }).then(() => {
        this.message = 'Done! You may now login using the new password.'
        this.done = true
      }).catch(error => {
        console.error(error)
        this.error = true
        setTimeout(() => this.error = false, 5000)
      }).finally(() => this.buttonState(true))
    }
  }
}
</script>