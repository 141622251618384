<template>
  <div id="board-settings">

    <ol class="options">
      <SettingsListItem v-for="item in options" :key="item.id" :class="{active: active && active.id === item.id}"
                        @click.native="change(item)">
        {{ item.label }}
      </SettingsListItem>
    </ol>
    <component class="options-page" :is="component"/>

  </div>
</template>

<script>
import SettingsListItem from '@/components/SettingsListItem'
import OptionsBasic from '@/components/singleBoard/settings/optionSet/OptionsBasic'
import OptionsSharing from '@/components/singleBoard/settings/optionSet/OptionsSharing'
import OptionsNotifications from '@/components/singleBoard/settings/optionSet/OptionsNotifications'

export default {
  name: 'BoardSettings',
  components: { SettingsListItem, OptionsBasic, OptionsSharing, OptionsNotifications },
  props: ['hash'],
  data() {
    return {
      options: [
        {
          id: 'basic',
          label: 'Basic',
          component: OptionsBasic
        },
        {
          id: 'sharing',
          label: 'Sharing',
          component: OptionsSharing
        },
        {
          id: 'notifications',
          label: 'Notifications',
          component: OptionsNotifications
        }
      ],
      active: undefined
    }
  },
  watch: {
    hash() {
      this.flip()
    }
  },
  computed: {
    component() {
      return this.active ? this.active.component : undefined
    }
  },
  methods: {
    change(item) {
      if (this.active !== item) {
        this.$router.replace({ hash: item.id })
        this.active = item
      }
    },
    flip() {
      this.active = this.options.find(el => el.id === this.hash) || this.options[0]
    }
  },
  mounted() {
    this.flip()
  }
}
</script>

<style lang="stylus" scoped>
#board-settings
  display grid
  grid-template-columns 1fr 1fr 1fr
  gap 40px
  padding-top 50px
  height 100%
  max-height 100%
  overflow-y scroll

  & > .options
    justify-self end
    white-space nowrap

  & > div
    justify-self center
</style>