<template>
  <div id="appsumo-signin-container">

    <header>
      <img id="boardflow-logo" alt="Boardflow" :src="require('@/assets/images/boardflow-logo-white.svg')" width="300"/>
      <span style="font-size: 3em; margin: 20px">❤️</span>
      <img id="appsumo-logo" alt="Boardflow" :src="require('@/assets/images/appsumo-logo.svg')" width="300"/>
    </header>

    <div id="features" v-if="!error">
      Great deal! By redeeming your AppSumo code
      <strong v-if="code">{{ $route.params.code }}</strong>
      you'll unlock a lifetime subscription to Boardflow, which includes:
      ✔️ Unlimited Boards, ✔️ Collaborators and Storage, ✔️ Public Boards, ✔️ Comments,
      ✔️ Advanced User Management and more!
    </div>

    <div id="panel" v-if="error">
      <span style="color: tomato">The AppSumo code <strong>{{ code }}</strong> is invalid or already redeemed.</span>
      <br/><br/>
      <a href="/appsumo">Try again</a><br/><br/>
      <a href="https://appsumo.com">Get an AppSumo code</a><br/><br/>
      <router-link to="/login">Login to Boardflow</router-link>
    </div>

    <div id="panel" v-if="show">
      <SigninForm :initialMode="'signup'" :modeSelector="false" :onSubmit="onSubmit" :disable-autofocus="true">
        <template v-slot:preform>
          <fieldset v-if="!code">
            <label>AppSumo Code</label>
            <input type="text" class="wide" required autofocus ref="code" tabindex="1"/>
          </fieldset>
        </template>
      </SigninForm>
    </div>

  </div>
</template>

<script>
import http from '@/http'
import SigninForm from '@/components/auth/SigninForm'

export default {
  name: 'AppsumoSignin',
  components: { SigninForm },
  data() {
    return {
      code: undefined,
      error: undefined,
      show: false
    }
  },
  methods: {
    onSubmit(data) {
      data.code = this.$route.params.code ?? this.$refs.code.value
      return http.post('/appsumo/redeem', data)
          .then(response => {
            localStorage.setItem('jwt', response.data.token)
            let target = localStorage.getItem('authTarget') || '/dashboard'
            localStorage.removeItem('authTarget')
            window.location.href = target
          }).catch(e => {
            console.log(e)
            this.error = true
          })
    }
  },
  beforeMount() {
    let code = this.$route.params.code
    if (code) {
      this.code = code
      http.get(`/appsumo/${code}`)
          .catch(() => this.error = true)
          .finally(() => this.show = true)
    } else {
      this.show = true
    }
  }
}
</script>

<style lang="stylus">
body
  overflow-y auto

#appsumo-signin-container
  display flex
  align-items center
  flex-direction column
  padding-bottom 100px
  min-height 100vh
  background linear-gradient(45deg, c-gradient-purple-dark, c-gradient-purple-light)

  header
    margin-top 100px

  #appsumo-logo
    color white

  #panel, #features
    width 450px
    margin-top 3vh
    padding 20px
    background white
    border-radius 10px
    box-shadow 0 0 20px rgba(0, 0, 0, 0.5)

.appsumo-code
  display inline-block
  padding 5px 10px
  border 2px dashed red
</style>