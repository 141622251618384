<template>
  <ul id="entries" ref="list">
    <EntryTile v-for="entry in entries" :key="entry.id" :entry="entry" :publicMode="publicMode"
               @mousedown.native="onDown"/>
  </ul>
</template>

<script>
import Wookmark from 'wookmark'
import EntryTile from '@/components/singleBoard/EntryTile'

export default {
  name: 'EntryList',
  components: { EntryTile },
  mounted() {
    this.doWookmark()
  },
  props: ['entries', 'publicMode'],
  watch: {
    entries() {
      this.$nextTick(() => this.doWookmark())
    }
  },
  methods: {

    doWookmark() {

      if (this.wookmark)
        this.wookmark.clear()

      this.wookmark = new Wookmark('#entries', {
        align: 'center',
        offset: 30,
        verticalOffset: 20,
        outerOffset: 20,
        itemWidth: 312,
        autoResize: true,
        resizeDelay: 50
      })

    },

    onDown: function(e) {

      if (!e.target.classList.contains('entry-tile')) return

      document.addEventListener('mousemove', this.onMove)
      document.addEventListener('mouseup', this.onUp)

      this.draggedEntry = e.target

      this.dX = e.pageX
      this.dY = e.pageY

      return false

    },

    onMove: function(e) {

      let parentElement = this.$refs.list.parentElement

      let offset = parentElement.offsetTop - parentElement.scrollTop

      if (this.dragging) {

        this.draggedEntry.style.top = (e.pageY - this.dY + offset) + 'px'
        this.draggedEntry.style.left = (e.pageX - this.dX) + 'px'

      } else {

        if (Math.max(Math.abs(e.pageX - this.dX), Math.abs(e.pageY - this.dY)) > 20) {

          this.dragging = true

          this.dX = e.pageX - this.draggedEntry.offsetLeft
          this.dY = e.pageY - this.draggedEntry.offsetTop

          this.draggedEntry.classList.add('dragging')
          this.draggedEntry.style.top = (e.pageY - this.dY + offset) + 'px'
          this.draggedEntry.style.left = (e.pageX - this.dX) + 'px'

          this.$refs.list.querySelectorAll('.entry-tile:not(.dragging)')
              .forEach(el => el.addEventListener('mouseenter', this.movePlaceholder))

          // move placeholder to correct place
          this.createPlaceholder(this.draggedEntry)

          // detach tile from list
          this.$refs.list.removeChild(this.draggedEntry)
          parentElement.appendChild(this.draggedEntry)

          this.doWookmark()

        }

      }

    },

    createPlaceholder: function(draggedEntry) {

      this.ph = document.createElement('li')
      this.ph.classList.add('entry-tile-placeholder')

      this.ph.style.display = 'block'
      this.ph.style.width = draggedEntry.offsetWidth + 'px'
      this.ph.style.height = draggedEntry.offsetHeight + 'px'
      this.ph.style.padding = '5px'
      this.ph.style.borderRadius = '5px'
      this.ph.style.backgroundColor = 'rgba(0, 0, 0, .05)'

      this.$refs.list.insertBefore(this.ph, draggedEntry)

    },

    movePlaceholder: function(e) {

      let children = Array.from(this.$refs.list.querySelectorAll('.entry-tile:not(.dragging), .entry-tile-placeholder'))

      this.$refs.list.insertBefore(this.ph,
          children.indexOf(this.ph) > children.indexOf(e.target)
              ? e.target
              : e.target.nextSibling)

      this.doWookmark()

    },

    onUp: function() {

      document.removeEventListener('mousemove', this.onMove)
      document.removeEventListener('mouseup', this.onUp)

      if (this.dragging) {

        this.dragging = false

        this.draggedEntry.classList.remove('dragging')
        this.draggedEntry.style.top = this.draggedEntry.style.left = 'auto'

        this.$refs.list.querySelectorAll('.entry-tile')
            .forEach(el => el.removeEventListener('mouseenter', this.movePlaceholder))

        // reattach tile to list
        this.$refs.list.parentElement.removeChild(this.draggedEntry)
        this.$refs.list.insertBefore(this.draggedEntry, this.ph)

        // move placeholder to the end
        this.$refs.list.removeChild(this.ph)
        this.ph = undefined

        this.doWookmark()

        let ids = [...this.$refs.list.querySelectorAll('li.entry-tile')].map(function(el) {
          return el.dataset.entryId
        })

        this.$store.dispatch('entries/sortEntries', ids)

      }

    }

  }
}
</script>

<style lang="stylus" scoped>
#entries
  width 100%
  position relative
</style>