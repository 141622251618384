<template>
  <div id="upload-box" v-show="uploads.length > 0">
    <div class="header">
      <span class="title">{{ runningUploads > 0 ? 'Uploading...' : 'Done!' }}</span>
      <span class="close" v-show="runningUploads === 0" @click="clear">X</span>
    </div>
    <ol class="upload-list">
      <UploadTask v-for="upload in uploads" :key="upload.id" :upload="upload"/>
    </ol>
  </div>
</template>

<script>
import UploadTask from '@/components/upload/UploadTask'
import { mapState } from 'vuex'

export default {
  name: 'UploadBox',
  components: { UploadTask },
  computed: mapState({
    currentBoard: s => s.boards.currentBoard,
    uploadTarget: s => s.uploads.uploadTarget,
    uploads: s => s.uploads.uploads,
    runningUploads: s => s.uploads.runningUploads
  }),
  methods: {
    clear() {
      this.$store.commit('uploads/CLEAR_UPLOADS')
    }
  },
  watch: {
    '$store.state.uploads.runningUploads': function(i) {
      if (i === 0 && this.$route.name === 'SingleBoard')
        this.$store.dispatch('entries/fetchEntries', this.currentBoard.id)
    }
  }
}
</script>

<style lang="stylus" scoped>

#upload-box
  position fixed
  bottom 0
  left 20px
  width 400px
  height 350px
  display flex
  flex-direction column
  border-radius 4px 4px 0 0
  box-shadow 0 0 5px 1px rgba(204, 204, 204, .75)
  overflow hidden

  .header
    display flex
    justify-content space-between
    padding 10px
    background-color c-primary
    color white

    .close
      cursor pointer

    .title
      pointer-events none

  .upload-list
    flex 1
    overflow-y auto
    scrollbar-width none
    background-color white

/*  &.started
    background-color rgba(black, .5)

  .dropit
    font-size 4em
    color white
    font-weight 700
    pointer-events none

  .uploader
    width 550px
    height 400px
    padding: 20px
    background-color white
    border-radius 10px

    .upload-list
      max-height 100%
      overflow-y auto*/
</style>