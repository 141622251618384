<template>
  <div id="public-board">
    <div v-if="notFound">Sorry, we can't find any public board at this link</div>
    <div v-else>
      <h1>{{ board.name }}</h1>
      <PublicEntryList :entries="board.entries"/>
      <PublicBigImage v-if="currentEntry"/>
    </div>
  </div>
</template>

<script>
import PublicEntryList from '@/components/publicBoard/PublicEntryList'
import PublicBigImage from '@/components/publicBoard/PublicBigImage'
import { mapState } from 'vuex'

export default {
  name: 'PublicBoard',
  components: { PublicEntryList, PublicBigImage },
  computed: mapState({
    board: s => s.publicBoard.board,
    notFound: s => s.publicBoard.notFound,
    currentEntry: s => s.publicBoard.currentEntry
  })
}
</script>

<style lang="stylus" scoped>
#public-board
  height 100%
  max-height 100%
  overflow-y scroll
  position relative

  h1
    margin 50px 0
    text-align center
    font-size 2em
    font-weight 700
</style>