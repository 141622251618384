<template>
  <div class="entry-sidebar">
    <ol class="switch">
      <li @click="flip(false)" :class="{ selected: !showComments }">Info</li>
      <li @click="flip(true)" :class="{ selected: showComments }">Comments</li>
    </ol>
    <EntryInfo v-show="!showComments"/>
    <EntryCommentsUnsupported v-if="!supportsComments" v-show="showComments"/>
    <EntryComments v-else v-show="showComments" ref="comments"/>
  </div>
</template>

<script>
import EntryComments from '@/components/singleEntry/sidebar/comments/EntryComments'
import EntryInfo from '@/components/singleEntry/sidebar/info/EntryInfo'
import { mapState } from 'vuex'
import EntryCommentsUnsupported from '@/components/singleEntry/sidebar/comments/EntryCommentsUnsupported'

export default {
  name: 'SingleEntrySideBar',
  components: { EntryCommentsUnsupported, EntryInfo, EntryComments },
  props: ['hash'],
  data() {
    return {
      showComments: false
    }
  },
  computed: {
    ...mapState({ currentBoard: s => s.boards.currentBoard }),
    supportsComments() {
      return this.currentBoard.owner.planFeatures.includes('COMMENTS')
    }
  },
  methods: {
    flip(comments) {
      this.showComments = comments
      if (this.supportsComments && comments)
        this.$nextTick(this.$refs.comments.scroll)
    }
  },
  mounted() {
    this.flip('comments' === this.hash)
  }
}
</script>

<style lang="stylus" scoped>
.entry-sidebar
  position absolute
  bottom 0
  top 0
  right 0
  display flex
  flex-direction column
  width 350px
  border-left 1px solid #ccc
  background-color white

  .switch
    display flex

    li
      text-align center
      padding 10px 0
      cursor pointer
      flex 50%
      border-bottom 1px solid #ccc

      &.selected
        border-bottom 1px solid c-primary
</style>