<template>
  <div id="support">
    <div></div>
    <div class="options-page">
      <section>
        <h1><span>Support</span></h1>
        In case support is needed, please reach us at <a href="mailto:support@creonomy.com">support@creonomy.com</a>
      </section>
      <section>
        <h1><span>Feedback</span></h1>
        Please use this <a href="https://docs.google.com/forms/d/e/1FAIpQLScYJsQSgIvktwOwP0qYZvRVfxdoQUeCEQepZtAkkh_kGiu5ag/viewform?usp=sf_link" target="_blank">feedback form</a>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Support'
}
</script>

<style lang="stylus">
#support
  display grid
  grid-template-columns 1fr 1fr 1fr
  gap 40px
  padding-top 50px
  height 100%
  max-height 100%
  overflow-y scroll
</style>