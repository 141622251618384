<template>
  <SigninForm :onSubmit="onSubmit" :initialMode="initialMode"/>
</template>

<script>
import http from '@/http'
import SigninForm from '@/components/auth/SigninForm'

export default {
  name: 'Signin',
  components: { SigninForm },
  props: ['initialMode'],
  methods: {
    onSubmit(data) {
      return http.post('/auth/' + data.form, data).then(response => {
        localStorage.setItem('jwt', response.data.token)
        let target = localStorage.getItem('authTarget') || '/dashboard'
        localStorage.removeItem('authTarget')
        window.location.href = target
      })
    }
  }
}
</script>