<template>
  <li>
    <strong>{{ notification.actorName }}</strong> has invited you to the board
    <strong>
      <router-link :to="`/board/${notification.boardId}`">{{ notification.boardName }}</router-link>
    </strong>
  </li>
</template>

<script>
export default {
  name: 'NotificationNewBoard',
  props: ['notification']
}
</script>