<template>
  <div class="entry-comments" ref="el">
    <div class="wrapper">
      <ol ref="list">
        <Comment v-for="comment in comments" :key="comment.id" :comment="comment"/>
      </ol>
    </div>
    <textarea @keydown.ctrl.enter="post" placeholder="Add a comment (CTRL+ENTER to send)" ref="area"/>
    <button @click="post">Send</button>
  </div>
</template>

<script>
import Comment from '@/components/singleEntry/sidebar/comments/Comment'
import { mapState } from 'vuex'

export default {
  name: 'EntryComments',
  components: { Comment },
  computed: mapState({ entry: s => s.entries.currentEntry, comments: s => s.comments.comments }),
  watch: {
    entry() {
      if (this.entry)
        this.$store.dispatch('comments/fetchComments', this.entry.id).then(this.scroll)
    }
  },
  methods: {
    post() {
      let comment = this.$refs.area.value.trim()
      if (!comment)
        return
      this.$store.dispatch('comments/postComment', { entryId: this.entry.id, comment }).then(this.scroll)
      this.$refs.area.value = ''
    },
    scroll() {
      this.$refs.list.scrollTop = this.$refs.list.scrollHeight
    }
  },
  mounted() {
    this.$store.dispatch('comments/fetchComments', this.entry.id)
  }
}
</script>

<style lang="stylus" scoped>
.entry-comments
  display flex
  flex-direction column
  height 100%
  padding 10px 10px 0 10px

  .wrapper
    flex 1
    position relative

    ol
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin-bottom 10px
      overflow-y auto
      scrollbar-color c-primary transparent
      scrollbar-width thin

      &::-webkit-scrollbar
        width 5px

      &::-webkit-scrollbar-thumb
        border 5px solid c-primary

  textarea
    height 6em

  button
    margin 10px 0

</style>