<template>
  <div class="modal" @click="dismiss" ref="overlay">
    <header>
      <div class="modal-icon">
        <fa :icon="['fad', icon]"/>
      </div>
      {{ title }}
    </header>
    <div class="modal-body">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['title', 'icon'],
  methods: {
    detectEsc(e) {
      if (e.key === 'Escape')
        this.$store.commit('DISMISS_MODAL')
    },
    dismiss(e) {
      if (e.target === this.$refs.overlay)
        this.$store.commit('DISMISS_MODAL')
    }
  },
  mounted() {
    document.addEventListener('keydown', this.detectEsc)
  },
  destroyed() {
    document.removeEventListener('keydown', this.detectEsc)
  }
}
</script>

<style lang="stylus" scoped>

fullpage()
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 10000

.wide
  header, .body
    width 60em !important

.modal
  fullpage()
  display flex
  justify-content top
  flex-direction column
  align-items center
  background-color rgba(black, .5)

  header, .modal-body
    width 30em

  header
    font-weight 700
    padding 10px 20px 8px 70px
    background-color lightgrey
    border-radius 15px 15px 0 0
    position relative
    margin-top 200px
    text-transform capitalize

    .modal-icon
      position absolute
      display flex
      align-items center
      justify-content center
      top 10px
      left 10px
      width 50px
      height 50px
      background-color white
      box-shadow 0 0 5px 0 gray
      border-radius 50px

      svg
        display block
        color c-primary
        width 25px
        height 25px

  .modal-body
    display flex
    flex-direction column
    padding 30px 20px 20px 20px
    background-color white
    border-radius 0 0 15px 15px

</style>