<template>
  <div>

    <section>
      <h1><span>Public Board</span></h1>
      <div v-if="owned && !plan.features.includes('PUBLIC_BOARDS')" class="upgrade">
        Your plan does not allow for public urls
        <button @click="$router.push('/settings#plans')">Upgrade plan</button>
      </div>
      <div v-else>
        <BoardCheckbox v-if="owned" label="Make this Board public"
                       :checked="!!currentBoard.publicUrl" @change="change"/>
        <span v-else>This board is not public and this setting can only be changed by the board's owner.</span>
        <div class="public-url" v-if="currentBoard.publicUrl">
          {{ `${frontendUrl}/public/${currentBoard.publicUrl}` }}
          <button class="small" @click="copy">Copy</button>
        </div>
      </div>
    </section>

    <section>
      <h1><span>Invite Collaborators</span></h1>
      <form v-if="owned" class="collaborator-form" @submit.prevent="invite">
        <fieldset style="flex: 1">
          <label>Email</label>
          <input type="email" class="wide" ref="email"/>
          <!--<span class="nudge">Choose frequently invited users</span>-->
        </fieldset>
        <fieldset>
          <label>
            Role
            <fa :icon="['fad', 'question-circle']"/>
            <RoleHelpPopup/>
          </label>
          <select ref="role">
            <option value="COLLABORATOR">Collaborator</option>
            <option v-if="plan.features.includes('USER_MANAGEMENT_ADVANCED')" value="VIEWER">Viewer</option>
          </select>
          <span v-if="!plan.features.includes('USER_MANAGEMENT_ADVANCED')" class="nudge">Upgrade for Viewers</span>
        </fieldset>
        <button>Add</button>
      </form>
      <div class="members">
        People in this board
        <UsersTable :sharings="accepted" empty-message="Just you!"/>
      </div>
      <div>
        Pending invitations
        <UsersTable :sharings="pending" empty-message="There are no pending invitations for this board"/>
      </div>
    </section>

  </div>
</template>

<script>
import BoardCheckbox from '@/components/form/BoardCheckbox'
import UsersTable from '@/components/singleBoard/settings/UsersTable'
import { mapGetters, mapState } from 'vuex'
import RoleHelpPopup from '@/components/singleBoard/settings/optionSet/RoleHelpPopup'

export default {
  name: 'OptionsSharing',
  components: { RoleHelpPopup, UsersTable, BoardCheckbox },
  computed: {
    ...mapState({ plan: s => s.plan, user: s => s.user, currentBoard: s => s.boards.currentBoard }),
    ...mapGetters('sharing', ['pending', 'accepted']),
    owned() {
      return this.user.id === this.currentBoard.owner.id
    },
    frontendUrl: () => process.env.VUE_APP_FRONTEND_URL
  },
  methods: {
    change(e) {
      this.$store.dispatch('boards/togglePublic', e)
    },
    copy() {
      navigator.clipboard.writeText(`${this.frontendUrl}/public/${this.currentBoard.publicUrl}`)
    },
    invite() {
      let invitation = {
        type: 'USER',
        email: this.$refs.email.value,
        role: this.$refs.role.value
      }
      this.$store.dispatch('sharing/createSharing', { sharing: invitation })
          .then(() => this.$refs.email.value = '')
    }
  },
  mounted() {
    this.$store.dispatch('sharing/fetchSharing')
  }
}
</script>

<style lang="stylus" scoped>
.public-url
  position relative
  padding 10px
  margin-top 10px
  background-color #efefef
  border 2px solid #c5c5c5
  border-radius 5px
  font-size .9em
  line-height 30px

  button
    position absolute
    top 10px
    right 10px
    line-height 20px

.upgrade
  display flex
  justify-content space-between
  align-items center

.members
  margin-bottom 30px

.collaborator-form
  display flex
  gap 20px
  align-items flex-end
  margin-bottom 50px

  .nudge
    position absolute
    font-size 0.8em
    margin-top 5px
    color c-primary

  label
    position relative

  svg
    color gray
    font-size 0.8rem
    cursor help

    &:hover + .tooltip
      display block
      top -100px
      left 45px

  fieldset
    margin-bottom 0
</style>