import { render, staticRenderFns } from "./UpgradeModal.vue?vue&type=template&id=b5e36f1a&scoped=true"
import script from "./UpgradeModal.vue?vue&type=script&lang=js"
export * from "./UpgradeModal.vue?vue&type=script&lang=js"
import style0 from "./UpgradeModal.vue?vue&type=style&index=0&id=b5e36f1a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.88.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5e36f1a",
  null
  
)

export default component.exports