<template>
  <component :is="this.$route.meta.layout || 'main'">
    <router-view/>
  </component>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="stylus">
@import '~@/assets/styles/reset.css'
@import '~@/assets/styles/default.styl'
@import '~@/assets/styles/forms.styl'
</style>