<template>
  <div id="big-image">
    <img :src="`${repositoryUrl}/${entry.boardId}/${entry.previewRepoKey}`" :width="entry.width"
         :height="entry.height"/>
    <fa :icon="['fad', 'times']" class="close" @click="close"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PublicBigImage',
  inheritAttrs: false,
  computed: {
    ...mapState({ entry: s => s.publicBoard.currentEntry }),
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL
  },
  methods: {
    detectEsc(e) {
      if (e.key === 'Escape')
        this.close()
    },
    close() {
      this.$store.commit('publicBoard/CURRENT_ENTRY', undefined)
    },
    keyboardNavigation(e) {
      if (e.target !== document.body)
        return
      switch (e.key) {
        case 'ArrowLeft':
          this.prev()
          break
        case 'ArrowRight':
          this.next()
          break
      }
    },
    prev() {
      this.$store.commit('publicBoard/NEXT_ENTRY')
    },
    next() {
      this.$store.commit('publicBoard/PREV_ENTRY')
    }
  },
  mounted() {
    console.log('mounted')
    document.addEventListener('keydown', this.keyboardNavigation)
    document.addEventListener('keydown', this.detectEsc)
  },
  destroyed() {
    console.log('destroy')
    document.removeEventListener('keydown', this.keyboardNavigation)
    document.removeEventListener('keydown', this.detectEsc)
  }
}
</script>

<style lang="stylus" scoped>
.close
  position fixed
  top .75em
  right 1em
  cursor pointer
  font-size 2em
  color #555

  &:hover
    color c-primary

#big-image
  background rgba(255, 255, 255, 0.5)
  position absolute
  top 20px
  left 20px
  bottom 20px
  right 20px

  img
    display block
    object-fit fill
    margin 0 auto
    box-shadow 0 0 5px 1px #ccc
</style>