<template>
  <div id="single-entry">
    <BigImage/>
    <SingleEntrySideBar :hash="hash"/>
  </div>
</template>

<script>
import BigImage from '@/components/singleEntry/BigImage'
import SingleEntrySideBar from '@/components/singleEntry/sidebar/SingleEntrySideBar'
import { mapState } from 'vuex'
import store from '@/store'

const prepareView = (to, from, next) => {
  if (from.name === 'SingleBoard') {
    // If standard navigation, no need to fetch list again
    store.commit('entries/CURRENT_ENTRY', to.params.entryId)
    next()
  } else {
    store.dispatch('entries/fetchEntries', to.params.boardId).then(() => {
      store.commit('boards/CURRENT_BOARD', to.params.boardId)
      store.commit('entries/CURRENT_ENTRY', to.params.entryId)
      next()
    })
  }
}

export default {
  name: 'SignleEntry',
  beforeRouteEnter: prepareView,
  beforeRouteUpdate: prepareView,
  components: { BigImage, SingleEntrySideBar },
  props: ['hash'],
  computed: mapState({ currentEntry: s => s.entries.currentEntry }),
  methods: {
    keyboardNavigation(e) {
      if (e.target !== document.body)
        return
      switch (e.key) {
        case 'ArrowLeft':
          this.prev()
          break
        case 'ArrowRight':
          this.next()
          break
      }
    },
    prev() {
      this.$store.commit('entries/PREV_ENTRY')
    },
    next() {
      this.$store.commit('entries/NEXT_ENTRY')
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keyboardNavigation)
  },
  destroyed() {
    document.removeEventListener('keydown', this.keyboardNavigation)
  }
}
</script>

<style lang="stylus" scoped>
#single-entry
  position relative
  height 100%
  max-height 100%
  overflow-y hidden
</style>