<template>
  <li class="entry-tile" :class="{ selected: entry.selected }" :data-entry-id="entry.id"
      @click.exact="goToEntry" @click.ctrl="toggleSelect">
    <div class="menu">
      <span class="title">{{ entry.title }}</span>
      <!--<ol v-if="entry.tags && entry.tags.length > 0">-->
      <!--  <li v-for="tag in entry.tags" :key="tag">{{ tag }}</li>-->
      <!--</ol>-->
      <BoardCheckbox v-if="!publicMode" :checked="entry.selected" @change="toggleSelect"/>
    </div>
    <img :src="`${repositoryUrl}/${entry.boardId}/${entry.thumbRepoKey}`" :width="entry.thumb.width" :height="entry.thumb.height"/>
    <!--<TileOptions :entry="entry"/>-->
  </li>
</template>

<script>
import BoardCheckbox from '@/components/form/BoardCheckbox'

export default {
  name: 'EntryTile',
  components: { BoardCheckbox },
  props: ['entry', 'publicMode'],
  computed: {
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL
  },
  methods: {
    goToEntry() {
      this.$router.push(`/board/${this.entry.boardId}/entry/${this.entry.id}`)
    },
    toggleSelect() {
      let module = this.search ? 'search' : 'entries'
      this.$store.commit(`${module}/SELECT_ENTRY`, { id: this.entry.id, selected: !this.entry.selected })
    }
  }
}
</script>

<style lang="stylus" scoped>

.entry-tile.dragging
  position absolute
  opacity .8
  z-index 10000
  pointer-events none

.entry-tile
  position relative
  display block
  padding 5px
  min-width 312px
  background white
  border 1px solid #ccc
  border-radius 5px
  box-shadow 0 0 8px rgba(0, 0, 0, 0.15)
  user-select none
  cursor pointer

  &.selected
    border-color c-primary
    box-shadow 0 0 8px rgba(155, 39, 176, .6)

    .menu
      display flex

  .menu
    display none
    position absolute
    padding 10px
    top 5px
    left 5px
    right 5px
    background rgba(255, 255, 255, .9)
    align-items flex-start
    gap 10px

    span
      flex 1
      font-weight 700
      font-size .9em
      display -webkit-box
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      overflow hidden

    ol
      display block
      margin-top 5px

    li
      background purple
      color white
      display inline
      padding 2px 5px
      border-radius 4px
      margin-right 5px
      font-size 10px
      text-transform uppercase

  &:hover
    .menu
      display flex

    .tile-options
      display block

  img
    display block
    max-width 300px
    margin 0 auto
    pointer-events none

</style>