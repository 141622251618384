<template>
  <li>
    <div class="meta">
      <!--<img class="avatar" :src="`https://www.gravatar.com/avatar/${comment.author.md5}`" width="30" height="30"/>-->
      <span class="author">{{ comment.author.fullName }}</span>
      <div class="options">
        <span class="date" :title="readable">{{ formatted }}</span>
      </div>
    </div>
    <div class="text">{{ comment.comment }}</div>
  </li>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'Comment',
  inheritAttrs: false,
  props: ['comment'],
  computed: {
    readable() {
      return DateTime.fromMillis(this.comment.ts).toLocaleString(DateTime.DATETIME_MED)
    },
    formatted() {
      const date = DateTime.fromMillis(this.comment.ts)
      return Math.abs(date.diffNow().as('seconds')) > 3 ? date.toRelative() : 'just now'
    }
  }
}
</script>

<style lang="stylus" scoped>
li
  margin-bottom 20px

  &:hover .options
    display inline

  .options
    font-size .8em
    line-height 1.2em
    display none

  .meta
    display flex
    justify-content space-between
    align-items center
    margin-bottom 5px

  .author
    flex 1
    font-size .9em
    line-height 1.2em
    font-weight 700
    font-style italic

  .avatar
    border-radius 3px

  .date
    text-align right
    font-style italic
    vertical-align top
    color gray
    padding-right 5px
</style>