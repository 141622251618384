<template>
  <div class="toggle">
    <label class="switch" ref="label">
      <input type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)">
      <span class="slider round"></span>
    </label>
    <span @click="$refs.label.click()">Apply annual billing discount <em>(Pro and Studio only)</em></span>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: ['value']
}
</script>

<style lang="stylus" scoped>
.toggle
  display flex
  align-items center
  gap 10px

span
  cursor pointer

label
  position relative
  display inline-block
  width 60px
  height 34px
  margin 0

  input
    display none

.slider
  position absolute
  cursor pointer
  top 0
  left 0
  right 0
  bottom 0
  background-color #ccc
  transition .4s

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: c-primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px c-primary;
}

input:checked + .slider:before {
  transform translateX(26px)
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>