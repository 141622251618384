import http from '../http'

export default {

    namespaced: true,

    state: {
        plans: []
    },

    mutations: {
        FETCH_PLANS: (state, plans) => state.plans = plans
    },

    actions: {

        fetchPlans({ commit }) {
            http.get('/plans').then(response => commit('FETCH_PLANS', response.data.plans))
        }

    }

}