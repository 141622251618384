<template>
  <div class="badge">
    <i class="left"></i>
    <i class="right"></i>
    via AppSumo
  </div>
</template>

<script>
export default {
  name: 'AppsumoBadge'
}
</script>

<style lang="stylus" scoped>
.badge
  position absolute
  left 0
  right 0
  bottom 5px
  background #58C777
  text-align center
  font-size 1em
  line-height 2em
  color: #FFF;
  box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.3);
</style>