import AppLayout from '@/layouts/AppLayout'
import UserSettings from '@/views/UserSettings'
import UserSettingsTools from '@/components/userSettings/UserSettingsTools'

export default [
    {
        path: '/settings', name: 'UserSettings', component: UserSettings,
        props: route => ({ hash: route.hash ? route.hash.substring(1) : undefined }),
        meta: { layout: AppLayout, toolbar: UserSettingsTools }
    }
]