<template>
  <form @submit.prevent="submit" class="signin-form">
    <ol class="mode-selector">
      <li class="active">Forgot your password?</li>
    </ol>
    <div class="fields">
      <div class="text">Please enter the email you used to register to Boardflow. You will receive a reset link.</div>

      <fieldset>
        <label>Email</label>
        <input type="email" class="wide" v-model.trim="username" required ref="input"/>
      </fieldset>

      <ResultLabel v-show="error" class="error">There was a problem resetting your password</ResultLabel>
      <ResultLabel v-show="message" class="confirm">{{ message }}</ResultLabel>

      <button class="grow" type="submit" ref="button">Reset password</button>
    </div>
  </form>
</template>

<script>
import http from '../http'
import ResultLabel from '@/components/auth/ResultLabel'

export default {
  name: 'ForgotPassword',
  components: { ResultLabel },
  data() {
    return {
      username: undefined,
      error: false,
      message: ''
    }
  },
  methods: {
    buttonState(enabled) {
      this.$refs.button.classList[enabled ? 'remove' : 'add']('loading')
      this.$refs.button.disabled = !enabled
    },
    submit() {
      this.buttonState(false)
      http.post('/auth/password/forgot', {
        username: this.username
      }).then(() => {
        this.message = 'Ok, you should receive an email containing a reset link soon'
      }).catch(error => {
        console.error(error)
        this.error = true
        setTimeout(() => this.error = false, 5000)
      }).finally(() => this.buttonState(true))
    }
  },
  mounted() {
    this.$refs.input.focus()
  }
}
</script>