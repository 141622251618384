<template>
  <div class="tags">
    <span v-if="!editing && tags.length === 0" @click="startEditingSession()">Edit tags</span>
    <ol v-else-if="tags.length > 0" @click="startEditingSession()" :class="{editing}">
      <li v-for="tag in tags" :key="tag" @click.stop="removeTag">
        {{ tag }}
      </li>
    </ol>

    <TagSelector v-show="editing" :options="options" @selected="addTag" @reset="esc"
                 :placeholder="'Edit tags (TAB or ENTER to add)'" ref="input"/>

    <button v-if="editing" class="grow" @click.stop="esc">Save</button>
  </div>
</template>

<script>
import TagSelector from "@/components/singleEntry/sidebar/info/TagSelector.vue";
import { mapState } from "vuex";

export default {
  name: 'EditableTags',
  components: { TagSelector },
  props: ['entry'],
  data() {
    return {
      tags: [],
      editing: false
    }
  },
  computed: {
    ...mapState({ options: s => s.tags })
  },
  methods: {
    initLocalTags() {
      this.tags = [...this.entry.tags]
    },
    startEditingSession() {
      if (!this.editing) {
        this.editing = true
        this.$nextTick(() => this.$refs.input.focus())
      }
    },
    addTag(e) {
      let tag = e.trim().toLowerCase()
      if (tag && !this.tags.includes(tag))
        this.tags.push(tag)
    },
    removeTag(e) {
      if (this.editing)
        this.tags.splice(this.tags.indexOf(e.target.innerText), 1)
      else this.startEditingSession()
    },
    esc() {
      if (!this.editing) return
      this.editing = false
      let sortedLocal = this.tags.sort()
      let sortedEntry = [...this.entry.tags].sort()
      let equal = sortedLocal >= sortedEntry && sortedLocal <= sortedEntry
      if (!equal)
        this.$store.dispatch('entries/editEntry', { tags: sortedLocal })
    }
  },
  watch: {
    entry: function() {
      this.editing = false
      this.initLocalTags()
    }
  },
  mounted() {
    this.initLocalTags()
  }
}
</script>

<style lang="stylus" scoped>
.tags
  padding 10px 0
  border-bottom 1px solid #ddd

  button
    margin-top 10px

  span
    display block
    padding 5px
    border-radius 5px
    cursor pointer
    font-style italic
    color #aaa

    &:hover
      background-color whitesmoke

  input
    border-width 1px
    padding 4px
    width 100%
    font-size 1em

  ol
    display flex
    flex-wrap wrap
    gap 8px
    cursor pointer

    li
      padding 1px 5px
      max-width 100%
      font-size .8em
      background #f2ddff
      border 1px solid c-primary
      border-radius 3px
      overflow hidden
      text-overflow ellipsis

    &.editing
      margin-bottom 10px

      li:hover
        cursor pointer
        text-decoration line-through
</style>