<template>
  <div>

    <section>
      <h1><span>Your Data</span></h1>
      <div class="username">Your email account is <strong>{{ user.username }}</strong></div>
      <form @submit.prevent="renameUser">
        <fieldset>
          <label>Full name</label>
          <input type="text" :value="user.fullName" placeholder="Full name" ref="name" @keydown.enter="renameUser">
        </fieldset>
        <div>
          <button>Save</button>
          <span>{{ changeNameResult }}</span>
        </div>
      </form>
    </section>

    <section>
      <h1><span>Change Password</span></h1>
      <form class="password" @submit.prevent="changePassword">
        <fieldset>
          <label>Current password</label>
          <input type="password" placeholder="Current password" required ref="pwdCurrent">
        </fieldset>
        <fieldset>
          <label>New password</label>
          <input type="password" placeholder="New password" required ref="pwdNew">
        </fieldset>
        <div>
          <button>Change</button>
          <span>{{ changePwdResult }}</span>
        </div>
      </form>
      <div class="reset">
        Or
        <router-link to="/forgot">reset password</router-link>
      </div>
    </section>

    <section>
      <h1><span>Delete Account</span></h1>
      Your account will be scheduled for immediate deletion.<br/><br/>
      <button class="alert">Delete</button>
    </section>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OptionsUserAccount',
  data() {
    return {
      changeNameResult: '',
      changePwdResult: ''
    }
  },
  computed: mapState({ user: s => s.user }),
  methods: {
    renameUser() {
      const fullName = this.$refs.name.value
      this.$store.dispatch('changeFullName', { fullName })
          .then(() => {
            this.changeNameResult = 'Done!'
          })
          .catch(() => {
            this.$refs.name.focus()
            this.changeNameResult = 'Something went wrong...'
          }).then(() => setTimeout(() => this.changeNameResult = '', 3000))
    },
    changePassword() {
      const password = this.$refs.pwdCurrent.value
      const newPassword = this.$refs.pwdNew.value
      this.$store.dispatch('changePassword', { password, newPassword })
          .then(() => {
            this.$refs.pwdCurrent.value = ''
            this.$refs.pwdNew.value = ''
            this.changePwdResult = 'The password has been changed'
          })
          .catch(() => {
            this.$refs.pwdCurrent.focus()
            this.changePwdResult = 'Something went wrong... wrong password?'
          }).then(() => setTimeout(() => this.changePwdResult = '', 3000))
    }
  }
}
</script>

<style lang="stylus" scoped>
.username
  margin-bottom 1em

input
  width 50%

form > div
  display flex
  flex-direction row
  align-items center
  gap 20px

.reset
  margin 20px 0
</style>