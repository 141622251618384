<template>
  <div id="resultBoards" class="search-page">
    <h1><span>Boards that match your search</span></h1>
    <ol v-if="boards.length > 0">
      <SearchResultBoardTile v-for="board in boards" :key="board.id" :board="board"/>
    </ol>
    <div v-else class="empty">- No boards</div>
  </div>
</template>

<script>
import SearchResultBoardTile from '@/components/search/global/SearchResultBoardTile'

export default {
  name: 'SearchResultBoardList',
  components: { SearchResultBoardTile },
  props: ['boards']
}
</script>

<style lang="stylus" scoped>
#resultBoards
  margin-bottom 30px

  h1
    margin-bottom 0

  .empty
    margin-top 10px
    margin-bottom 40px
</style>