import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginAndPasswordReset from '@/router/router_login'
import Dashboard from '@/router/router_dashboard'
import SingleBoard from '@/router/router_singleBoard'
import SingleEntry from '@/router/router_singleEntry'
import UserSettings from '@/router/router_userSettings'
import Search from '@/router/router_search'
import Invitations from '@/router/router_invitations'
import PublicBoard from '@/router/router_publicBoard'
import Appsumo from '@/router/router_appsumo'
import StyleguideLayout from '@/layouts/StyleguideLayout'
import Styleguide from '@/views/Styleguide'
import AppLayout from '@/layouts/AppLayout'
import Support from '@/views/Support'
import UserSettingsTools from '@/components/userSettings/UserSettingsTools'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [

        { path: '*', redirect: '/dashboard' }, // not found, catchall
        { path: '/', redirect: '/dashboard' },
        {
            path: '/support', name: 'Support', component: Support,
            meta: { layout: AppLayout, toolbar: UserSettingsTools }
        },
        { path: '/styleguide', name: 'Styleguide', component: Styleguide, meta: { layout: StyleguideLayout } },

        ...LoginAndPasswordReset,
        ...Dashboard,
        ...SingleBoard,
        ...SingleEntry,
        ...UserSettings,
        ...Search,
        ...Invitations,
        ...PublicBoard,
        ...Appsumo

    ]
})

const originalPush = router.push
router.push = function push(location) {
    if (this.currentRoute.fullPath !== location) // To avoid DuplicateNavigation errors
        return originalPush.call(this, location)
}

export default router