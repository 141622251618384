<template>
  <div class="tag-selector">

    <input type="text" @input="input" :placeholder="placeholder"
           @keydown.enter.tab.prevent="go" @keydown.esc="reset"
           @keydown.down.stop.prevent="next" @keydown.up.stop.prevent="prev" ref="input"/>

    <div class="options" :class="{ showOptions }">
      <TagSelectorOption v-for="(option, i) in filteredOptions" :key="option" :option="option"
                         :selected="selectedIndex === i" @selected="dropdownClick"/>
    </div>

  </div>
</template>

<script>
import TagSelectorOption from "@/components/singleEntry/sidebar/info/TagSelectorOption.vue";

export default {
  name: 'TagSelector',
  components: { TagSelectorOption },
  props: {
    placeholder: String,
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      term: undefined,
      selectedIndex: undefined
    }
  },
  computed: {
    showOptions() {
      return this.term && this.filteredOptions.length > 0
    },
    filteredOptions() {
      return this.term ? this.options.filter(o => o.includes(this.term)) : []
    }
  },
  methods: {
    input(e) {
      this.term = e.target.value
      this.selectedIndex = undefined
    },
    go() {
      this.emitAndClear(this.$refs.input.value)
    },
    dropdownClick(option) {
      this.emitAndClear(option)
    },
    emitAndClear(option) {
      this.$emit('selected', option)
      this.clearField()
      this.focus()
    },
    focus() {
      this.$refs.input.focus()
    },
    reset() {
      const emit = this.$refs.input.value === ''
      this.clearField()
      if (emit)
        this.$emit('reset')
    },
    clearField() {
      this.term = undefined
      this.selectedIndex = undefined
      this.$refs.input.value = ''
    },
    prev() {
      if (this.filteredOptions.length > 0) {
        this.selectedIndex = isNaN(this.selectedIndex) ? 0 : Math.abs((this.selectedIndex - 1) % this.filteredOptions.length)
        this.$refs.input.value = this.filteredOptions[this.selectedIndex]
      }
    },
    next() {
      if (this.filteredOptions.length > 0) {
        this.selectedIndex = isNaN(this.selectedIndex) ? 0 : Math.abs((this.selectedIndex + 1) % this.filteredOptions.length)
        this.$refs.input.value = this.filteredOptions[this.selectedIndex]
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.tag-selector
  position relative

.options
  position relative
  display none
  left 0
  right 0
  top 2px
  border-radius v-form-radius
  border 1px solid c-form-border
  box-shadow v-form-shadow
  padding 5px
  background-color white
  z-index 9999

  &.showOptions
    display block

  .divider
    font-weight 600
    font-size .8em

.tag-selector input
  width 100%

  &::placeholder
    color lightgray

</style>