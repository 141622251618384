<template>
  <div>

    <section>

      <h1><span>Your Plan</span></h1>
      <div class="plan">
        <div class="summary">
          <h1>{{ plan.label }}</h1>
          <div class="price">
            <span class="currency">$</span>
            {{ plan.price / 100 }}
          </div>
          <em class="interval" v-if="plan.interval !== 'NEVER'">Billed {{ interval }}</em>
          <AppsumoBadge v-show="plan.name.includes('APPSUMO')"/>
        </div>
        <div class="features">
          <table>
            <tr>
              <td>
                <fa :icon="['fad', 'thumbs-up']" :rotation="hasUnlimitedBoards ? null : 180"
                    :class="{ yes: hasUnlimitedBoards }"/>
              </td>
              <td>Unlimited Boards</td>
            </tr>
            <tr>
              <td>
                <fa :icon="['fad', 'thumbs-up']" :rotation="hasUnlimitedStorage ? null : 180"
                    :class="{ yes: hasUnlimitedStorage }"/>
              </td>
              <td>Unlimited Storage</td>
            </tr>
            <tr>
              <td>
                <fa :icon="['fad', 'thumbs-up']" :rotation="hasUnlimitedCollabs ? null : 180"
                    :class="{ yes: hasUnlimitedCollabs }"/>
              </td>
              <td>Unlimited Collaborators</td>
            </tr>
            <tr>
              <td>
                <fa :icon="['fad', 'thumbs-up']" :rotation="hasPublicBoards ? null : 180"
                    :class="{ yes: hasPublicBoards }"/>
              </td>
              <td>Public Boards</td>
            </tr>
            <tr>
              <td>
                <fa :icon="['fad', 'thumbs-up']" :rotation="hasComments ? null : 180" :class="{ yes: hasComments }"/>
              </td>
              <td>Comments</td>
            </tr>
            <tr>
              <td><b>{{ plan.features.includes('USER_MANAGEMENT_ADVANCED') ? 'Advanced' : 'Basic' }}</b></td>
              <td>User Management</td>
            </tr>
            <tr>
              <td><b>{{ plan.features.includes('SUPPORT_PRIORITY') ? 'Priority' : 'Basic' }}</b></td>
              <td>Support</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="subscription.nextCharge" class="next-charge">
        Your next charge will be on {{ nextCharge }}
      </div>
      <div v-if="subscription.expiration" class="cancelled">
        Your subscription is cancelled.<br/>You will be reverted to the free plan on {{ expirationDate }}
      </div>
      <!--      <div class="actions">
              <button v-if="isFreePlan" class="grow" @click="$router.push('/upgrade')">
                Upgrade to a premium plan
              </button>
              <button v-if="!isFreePlan && !subscription.cancelled" class="grow" :class="{ loading: loadingSection }"
                      :disabled="loadingSection" @click="portalSection('update')">
                Choose a different plan
              </button>
              <button v-if="!isFreePlan && subscription.cancelled" class="grow" :class="{ loading: loadingSection }"
                      :disabled="loadingSection" @click="portalSection('reactivate')">
                Reactivate plan at no cost
              </button>
            </div>-->

    </section>

    <section>
      <h1><span>All Plans</span></h1>
      <div v-show="!showPlans">
        If you wish to change your plan or billing cycle, you can do so here.<br/><br/>
        <button @click="showPlans = true">Show the Plans</button>
      </div>
      <Payment v-show="showPlans"/>
    </section>

    <section v-if="!isTrial">
      <h1><span>Payment Methods, Invoices and Billing Info</span></h1>
      Your payment methods, invoices and billing information are securely managed by Stripe.<br/><br/>
      <button :class="{ loading: loadingPortal }" :disabled="loadingPortal" @click="portal()">
        Access your Customer Portal
      </button>
    </section>

    <section v-if="!isTrial && !subscription.cancelled">
      <h1><span>Cancel Subscription</span></h1>
      You can cancel your subscription at any time by accessing the customer portal.<br/><br/>
      <button class="alert" :class="{ loading: loadingSection }" :disabled="loadingSection"
              @click="portalSection('cancel')">
        Cancel Subscription
      </button>
    </section>

  </div>
</template>

<script>
import AppsumoBadge from '@/components/userSettings/AppsumoBadge'
import Payment from '@/components/userSettings/plans/Payment'
import { DateTime } from 'luxon'
import { mapState } from 'vuex'
import http from '@/http'

export default {
  name: 'OptionsPlans',
  components: { AppsumoBadge, Payment },
  data() {
    return {
      showPlans: false,
      loadingSection: false,
      loadingPortal: false
    }
  },
  computed: {
    ...mapState({ subscription: s => s.subscription, plan: s => s.plan, plans: s => s.plans.plans }),
    interval() {
      return this.plan.interval === 'DAY' ? 'daily' : this.plan.interval === 'MONTH' ? 'monthly' : 'annually'
    },
    nextCharge() {
      return DateTime.fromMillis(this.subscription.nextCharge).toLocaleString(DateTime.DATE_HUGE)
    },
    expirationDate() {
      return DateTime.fromMillis(this.subscription.expiration).toLocaleString(DateTime.DATE_HUGE)
    },
    hasUnlimitedBoards() {
      return this.plan.features.includes('UNLIMITED_BOARDS')
    },
    hasUnlimitedStorage() {
      return this.plan.features.includes('UNLIMITED_STORAGE')
    },
    hasUnlimitedCollabs() {
      return this.plan.features.includes('UNLIMITED_COLLABORATORS')
    },
    hasPublicBoards() {
      return this.plan.features.includes('PUBLIC_BOARDS')
    },
    hasComments() {
      return this.plan.features.includes('COMMENTS')
    },
    isTrial() {
      return this.plan.name.includes('TRIAL')
    }
  },
  methods: {
    portalSection(action) {
      this.loadingSection = true
      http.post(`/stripe/customer/session?action=${action}`)
          .then(session => window.location.href = session.data.url)
    },
    portal() {
      this.loadingPortal = true
      http.post(`/stripe/customer/session`)
          .then(session => window.location.href = session.data.url)
    }
  }
}
</script>

<style lang="stylus" scoped>
.actions
  display flex
  align-items flex-start
  gap 30px
  margin-top 30px

.next-charge
  margin-top 30px
  font-weight 700

.cancelled
  color tomato
  margin-top 30px
  font-weight 700

.plan
  display flex
  gap 80px

  .features
    flex 1

    table
      td
        padding 5px 10px

        &:nth-child(1)
          text-align right

        svg
          color red

          &.yes
            color green

  .summary
    position relative
    border 1px solid lightgrey
    box-shadow 1px 1px 5px lightgrey
    padding 20px
    min-width 12em
    border-radius 5px
    text-align center

    h1
      margin-bottom 5px
      font-size 2em
      font-weight 700

    .price
      display inline-block
      position relative
      font-size 4em
      font-weight 700
      text-shadow -1px 1px 5px #cecece

      .currency
        position absolute
        left -20px
        bottom 14px
        font-size .5em

    .interval
      display block

  button
    margin-top 20px
</style>