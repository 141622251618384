<template>
  <div class="search">

    <input type="text" :value="search" @input="input" @blur="reset" placeholder="Search..."
           @keydown.esc="reset" @keydown.enter="go"
           @keydown.down="$store.commit('search/NEXT_RESULT')"
           @keydown.up="$store.commit('search/PREV_RESULT')" ref="input"/>

    <fa :icon="['fad', 'search']"/>

    <div class="suggestions" :class="{ showSuggestions }">
      <div v-if="boards.length" class="divider">Boards</div>
      <SearchBarResult v-for="result in boards" :key="`${result.type}-${result.id}`" :result="result"/>
      <div v-if="tags.length" class="divider">Tags</div>
      <SearchBarResult v-for="result in tags" :key="`${result.type}-${result.id}`" :result="result"/>
      <hr v-if="boards.length || tags.length" class="divider"/>
      <SearchBarResult v-for="result in actions" :key="`${result.type}-${result.id}`" :result="result"/>
    </div>

  </div>
</template>

<script>
// import _ from 'lodash'
import SearchBarResult from '@/components/search/bar/SearchBarResult'
import { mapState } from 'vuex'

export default {
  name: 'SearchBar',
  components: { SearchBarResult },
  computed: {
    ...mapState({ search: s => s.search.search, suggestions: s => s.search.suggestions }),
    boards() {
      return this.suggestions.filter(r => r.type === 'BOARD')
    },
    tags() {
      return this.suggestions.filter(r => r.type === 'TAG')
    },
    actions() {
      return this.suggestions.filter(r => r.type === 'ACTION')
    },
    showSuggestions() {
      return this.suggestions.length > 0
    }
  },
  methods: {
    // debounced: _.debounce(function(e) {
    //   this.$store.dispatch('search/searchLocal', e.target.value)
    // }, 50, { leading: true }),
    input(e) {
      this.$store.dispatch('search/searchLocal', e.target.value)
    },
    go() {
      this.$store.dispatch('search/goToResult')
      this.clearField()
    },
    reset() {
      this.$store.commit('search/RESET_SEARCH')
      this.clearField()
    },
    clearField() {
      this.$refs.input.value = ''
      this.$refs.input.blur()
    },
    start(e) {
      if (e.key === '/' && e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA')
        this.$refs.input.focus()
    }
  },
  mounted() {
    document.addEventListener('keyup', this.start)
  }
}
</script>

<style lang="stylus" scoped>
.search
  position relative

  input
    padding-left 35px

  svg
    position absolute
    top 11px
    left 11px
    pointer-events none

.suggestions
  position absolute
  display none
  left 0
  right 0
  box-shadow 0 2px 3px rgba(0, 0, 0, .5)
  border-radius 0 0 5px 5px
  padding 10px
  background-color white
  z-index 9999

  &.showSuggestions
    display block

  .divider
    font-weight 600
    font-size .8em

.search input
  width 500px
  transition width .2s ease-in-out
  cursor pointer

  &::placeholder
    color lightgray

</style>