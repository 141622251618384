<template>
  <form @submit.prevent="submit" class="signin-form" ref="form">
    <ol class="mode-selector" v-if="modeSelector">
      <li @click="switchTo('signin')" :class="{active: signin}">Log In</li>
      <li @click="switchTo('signup')" :class="{active: signup}">Register</li>
    </ol>
    <div class="fields">

      <slot name="preform"></slot>

      <fieldset v-show="signup">
        <label>Full name</label>
        <input type="text" class="wide" v-model.trim="fullname" :required="signup" ref="fullname" tabindex="1"/>
      </fieldset>

      <fieldset>
        <label>Email</label>
        <input type="email" class="wide" v-model.trim="username" required ref="username" tabindex="2"/>
      </fieldset>

      <fieldset>
        <label>Password</label>
        <input type="password" class="wide" v-model.trim="password" required ref="pwd" tabindex="3"/>
        <router-link v-show="signin" class="forgot" to="/forgot" tabindex="6">Forgot your password?</router-link>
      </fieldset>

      <fieldset v-show="signup">
        <BoardCheckbox v-model="tos" :required="signup" tabindex="4">
          Agree to our <a href="https://www.boardflow.co/terms" target="_blank">Terms of Service</a>
        </BoardCheckbox>
      </fieldset>

      <slot name="postform"></slot>

      <ResultLabel v-show="error" class="error">We couldn't sign you {{ signin ? 'in' : 'up' }}</ResultLabel>

      <button class="grow" type="submit" ref="button" tabindex="5">{{ signin ? 'Log In' : 'Register' }}</button>
    </div>
  </form>
</template>

<script>
import BoardCheckbox from '@/components/form/BoardCheckbox'
import ResultLabel from '@/components/auth/ResultLabel'

export default {
  name: 'SigninForm',
  components: { ResultLabel, BoardCheckbox },
  props: {
    initialMode: {
      type: String,
      default: 'signin'
    },
    modeSelector: {
      type: Boolean,
      default: true
    },
    onSubmit: {
      type: Function
    },
    disableAutofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: undefined,
      username: undefined,
      password: undefined,
      fullname: undefined,
      tos: false,
      error: false
    }
  },
  computed: {
    signin() {
      return this.form === 'signin'
    },
    signup() {
      return this.form === 'signup'
    }
  },
  methods: {
    buttonState(enabled) {
      this.$refs.button.classList[enabled ? 'remove' : 'add']('loading')
      this.$refs.button.disabled = !enabled
    },
    switchTo(form) {
      this.form = form
      this.$nextTick(() => this.$refs[this.form === 'signin' ? 'username' : 'fullname'].focus())
    },
    submit() {
      this.buttonState(false)

      const data = {
        form: this.form,
        username: this.username,
        password: this.password,
        fullname: this.fullname
      }

      this.onSubmit(data)
          .catch(error => {
            console.error(error)
            this.error = true
            this.$refs.pwd.focus()
            setTimeout(() => this.error = false, 5000)
          })
          .finally(() => this.buttonState(true))
    }
  },
  beforeMount() {
    this.form = this.initialMode
  },
  mounted() {
    if (!this.disableAutofocus)
      this.$refs[this.form === 'signin' ? 'username' : 'fullname'].focus()
  }
}
</script>

<style lang="stylus" scoped>
.forgot
  font-size 0.9rem
  display inline-block
  margin-top 2px
</style>