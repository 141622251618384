import AppLayout from '@/layouts/AppLayout'
import SingleBoard from '@/views/SingleBoard'
import SingleBoardTools from '@/components/singleBoard/SingleBoardTools'
import BoardSettings from '@/views/BoardSettings'
import BoardSettingsTools from '@/components/singleBoard/settings/BoardSettingsTools'
import store from '@/store'

export default [
    {
        path: '/board/:id', name: 'SingleBoard', component: SingleBoard,
        meta: { layout: AppLayout, toolbar: SingleBoardTools }
        // Navigation guards in component
    },
    {
        path: '/board/:id/settings', name: 'BoardSettings', component: BoardSettings,
        props: route => ({ hash: route.hash ? route.hash.substring(1) : undefined }),
        meta: { layout: AppLayout, toolbar: BoardSettingsTools },
        beforeEnter: (to, from, next) => {
            store.commit('boards/CURRENT_BOARD', to.params.id)
            store.commit('entries/CURRENT_ENTRY', null)
            store.commit('search/RESET_SEARCH')
            next()
        }
    }
]