<template>
  <tr>
    <td class="email">{{ sharing.email }}</td>
    <td class="email">{{ sharing.role }}</td>
    <td v-if="canDelete" class="actions">
      <fa :icon="['fad', 'trash']" @click="withdraw" style="cursor: pointer"/>
    </td>
  </tr>
</template>

<script>
import WithdrawInvitationModal from '@/components/modals/WithdrawInvitationModal'

export default {
  name: 'UsersTableListItem',
  props: ['sharing', 'canDelete'],
  methods: {
    withdraw() {
      this.$store.commit('SHOW_MODAL', { modal: WithdrawInvitationModal, props: { sharing: this.sharing } })
    }
  }
}
</script>

<style lang="stylus" scoped>
tr
  td
    padding 10px

  .email
    width 75%

  .role
    width 25%

  .actions
    padding-right 20px

  .actions svg
    visibility hidden

  &:hover
    .actions svg
      visibility visible
</style>