<template>
  <SigninForm :initial-mode="'signup'" :onSubmit="onSubmit"/>
</template>

<script>
import http from '../http'
import SigninForm from '@/components/auth/SigninForm'

export default {
  name: 'Invitation',
  components: { SigninForm },
  props: ['code'],
  methods: {
    onSubmit(data) {
      return http.post(`/invitation/code/${this.code}`, data).then(response => {
        localStorage.setItem('jwt', response.data.token)
        let target = localStorage.getItem('authTarget') || '/dashboard'
        localStorage.removeItem('authTarget')
        window.location.href = target
      })
    }
  }
}
</script>