<template>
  <div class="title">
    <span v-if="!editing" @click="click">{{ entry.title }}</span>
    <input v-else type="text" :value="entry.title" placeholder="Edit title" @keydown.enter="enter" @keydown.esc="esc" ref="input"/>
  </div>
</template>

<script>
export default {
  name: 'EditableTitle',
  props: ['entry'],
  data() {
    return { editing: false }
  },
  methods: {
    click() {
      this.editing = true
      this.$nextTick(() => this.$refs.input.select())
    },
    enter(e) {
      if (e.target.value.trim())
        this.$store.dispatch('entries/editEntry', { title: e.target.value }).then(() => this.editing = false)
    },
    esc() {
      this.editing = false
    }
  },
  watch: {
    entry: function() {
      this.editing = false
    }
  }
}
</script>

<style lang="stylus" scoped>
span
  display block
  padding 5px
  border-radius 5px
  cursor pointer
  font-weight 700
  font-size 1.2em
  word-break break-word

  &:hover
    background-color whitesmoke

input
  border-width 1px
  padding 4px
  width 100%
  font-weight 700
  font-size 1.2em
  &::placeholder
    font-style italic
</style>