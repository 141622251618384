<template>
  <ol id="dashboard-filters">
    <li @click="toggleStarred" :class="{active: starred}">Favorites</li>
    <li class="separator">&nbsp;</li>
    <li @click="toggleOwned" :class="{active: owned}">Owned by me</li>
    <li @click="toggleShared" :class="{active: shared}">Shared with me</li>
  </ol>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardFilters',
  computed: mapState({
    starred: s => s.boards.filter.starred,
    owned: s => s.boards.filter.owned,
    shared: s => s.boards.filter.shared
  }),
  methods: {
    toggleStarred() {
      this.$store.commit('boards/SET_FILTER', { name: 'starred', value: !this.starred })
    },
    toggleOwned() {
      let value = !this.owned
      this.$store.commit('boards/SET_FILTER', { name: 'owned', value })
      if (value)
        this.$store.commit('boards/SET_FILTER', { name: 'shared', value: !value })
    },
    toggleShared() {
      let value = !this.shared
      this.$store.commit('boards/SET_FILTER', { name: 'shared', value })
      if (value)
        this.$store.commit('boards/SET_FILTER', { name: 'owned', value: !value })
    }
  }
}
</script>

<style lang="stylus">
#dashboard-filters
  display flex
  flex-direction row

  li:not(.separator)
    margin 10px 0 0 10px
    padding 5px 10px
    font-size .9em
    background-color #ededed
    border-radius 3px
    cursor pointer
    text-shadow 0 1px 0 whitesmoke

    &.active
      background-color c-primary-extra-light
      color whitesmoke
      text-shadow 0 1px 0 black

  li.separator
    margin 10px 0 0 10px
    border-left 1px solid lightgray
</style>