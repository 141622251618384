<template>
  <span><slot/></span>
</template>

<script>
export default {
  name: 'ResultLabel'
}
</script>

<style lang="stylus" scoped>

.error
  display block
  color tomato
  margin-bottom 20px

.confirm
  display block
  margin-bottom 20px
  padding 10px
  color white
  text-shadow 1px 1px 0 rgba(0, 0, 0, .2)
  background-color hsl(130, 85%, 40%)

</style>