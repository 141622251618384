<template>
  <div>
    <div v-if="sharings.length === 0" class="empty">
      {{ emptyMessage }}
    </div>
    <table v-else>
      <tbody>
      <UsersTableListItem v-for="sharing in sharings" :key="sharing.email" :sharing="sharing"
                          :can-delete="currentBoard.role !== 'VIEWER'"/>
      </tbody>
    </table>
  </div>
</template>

<script>
import UsersTableListItem from '@/components/singleBoard/settings/UsersTableListItem'
import { mapState } from 'vuex'

export default {
  name: 'UsersTable',
  components: { UsersTableListItem },
  props: ['sharings', 'emptyMessage'],
  computed: {
    ...mapState({ currentBoard: s => s.boards.currentBoard })
  }
}
</script>

<style lang="stylus" scoped>
.empty
  padding 20px
  text-align center
  font-style italic

table
  width 100%
  margin-top 10px
  border 1px solid lightgrey
  border-radius 5px

  td
    padding 10px

  .email
    width 75%

  .role
    width 25%

  tr:nth-child(even)
    background-color lightgrey
</style>