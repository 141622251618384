import AppLayout from '@/layouts/AppLayout'
import Dashboard from '@/views/Dashboard'
import DashboardTools from '@/components/dashboard/DashboardTools'
import store from '@/store'

export default [
    {
        path: '/dashboard', name: 'Dashboard', component: Dashboard,
        meta: { layout: AppLayout, toolbar: DashboardTools },
        beforeEnter: (to, from, next) => {
            // Cleaning up state
            store.commit('boards/CURRENT_BOARD', null)
            store.commit('entries/CURRENT_ENTRY', null)
            next()
        }
    }
]