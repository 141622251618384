import SigninLayout from '@/layouts/SigninLayout'
import Signin from '@/views/Signin'
import ForgotPassword from '@/views/ForgotPassword'
import ResetPassword from '@/views/ResetPassword'

export default [
    {
        path: '/login', alias: '/signin', name: 'Signin', component: Signin,
        props: { initialMode: 'signin' }, meta: { layout: SigninLayout }
    },
    {
        path: '/register', alias: '/signup', name: 'Signup', component: Signin,
        props: { initialMode: 'signup' }, meta: { layout: SigninLayout }
    },
    { path: '/forgot', name: 'ForgotPassword', component: ForgotPassword, meta: { layout: SigninLayout } },
    { path: '/reset/:code', name: 'ResetPassword', component: ResetPassword, meta: { layout: SigninLayout } }
]