<template>
  <div id="user-dropdown">
    <!--    <img class="gravatar" :src="`https://www.gravatar.com/avatar/${user.md5}`" height="30" width="30"/>-->
    <span>Hello, {{ user.fullName }}</span>
    <fa :icon="['fad', 'ellipsis-v-alt']"/>
    <ol class="user-dropdown-content">
      <li @click="$router.replace('/settings#account')">Account Settings</li>
      <li @click="$router.replace('/settings#plans')">Plans &amp; Pricing</li>
      <li><a href="https://www.boardflow.co/extension" target="_blank">Browser Extension</a>
      </li>
      <li @click="$router.replace('/support')">Support &amp; Feedback</li>
      <li>
        <hr/>
      </li>
      <li>
        <button class="alert grow" @click="logout">Logout</button>
      </li>
    </ol>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserDropdown',
  computed: mapState(['user']),
  methods: {
    logout() {
      localStorage.removeItem('jwt')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="stylus">
#user-dropdown
  position relative
  z-index 1000
  cursor pointer

  svg
    margin-top -2px
    margin-left 8px
    vertical-align middle
    color c-text

  span
    vertical-align middle

  .gravatar
    height 30px
    width 30px
    border-radius 3px
    vertical-align middle

  .user-dropdown-content
    display none
    position absolute
    right 0
    width 250px
    padding 15px
    background-color white
    border 1px solid c-primary
    border-radius 5px
    cursor auto
    box-shadow 0 0 3px 0 rgba(0, 0, 0, .3), 0 1px 3px 0 rgba(0, 0, 0, .2)

    li
      font-weight 600
      margin-bottom 15px
      cursor pointer

      a
        text-decoration none
        color c-text

      &:hover, &:hover a
        color c-primary

      &:last-child
        margin-bottom 0
        margin-top 20px

  &:hover .user-dropdown-content
    display block
</style>