import Vue from 'vue'
import http from '../http'

function addExtraParams(b) {
    b.selected = false
    b.sharing = []
}

export default {

    namespaced: true,

    state: {
        boards: [],
        currentBoard: undefined,
        filter: {
            starred: false,
            owned: false,
            shared: false
        }
    },

    getters: {
        selectedBoards: state => state.boards.filter(b => b.selected),
        filteredBoards: (state, getters, rootState) => {
            let shouldFilter = state.filter.starred || state.filter.owned || state.filter.shared
            return shouldFilter
                ? state.boards.filter(b => (state.filter.starred && b.star) || (state.filter.owned && b.ownerId === rootState.user.id) || (state.filter.shared && b.shared))
                : state.boards
        }
    },

    mutations: {
        SET_FILTER: (state, filter) => {
            state.filter[filter.name] = filter.value
        },
        FETCH_BOARDS: (state, boards) => {
            boards.forEach(addExtraParams)
            state.boards = boards
        },
        CURRENT_BOARD(state, id) {
            state.currentBoard = id ? state.boards.find(b => b.id === id) : undefined
        },
        ADD_BOARD: (state, board) => {
            state.boards.unshift(board)
        },
        SELECT_BOARD(state, p) {
            state.boards.find(b => b.id === p.id).selected = p.selected
        },
        SELECT_ALL_BOARDS(state, getters) {
            const s = state.boards.length !== getters.selectedBoards.length
            state.boards.forEach(b => b.selected = s)
        },
        TOGGLE_STAR(state, p) {
            state.boards.find(b => b.id === p.id).star = p.star
        },
        REMOVE_BOARDS(state, ids) {
            state.boards.filter(b => ids.includes(b.id)).forEach(b => state.boards.splice(state.boards.indexOf(b), 1))
        },
        SORT_BOARDS(state, ids) {
            const ordering = {}
            ids.forEach((id, i) => ordering[ids[i]] = i)
            state.boards.sort((a, b) => ordering[a.id] - ordering[b.id])
        },
        SET_PUBLIC_URL(state, publicUrl) {
            if (state.currentBoard) // We use set because this property is absent when non public
                Vue.set(state.currentBoard, 'publicUrl', publicUrl)
        },
        SET_NOTIFICATIONS(state, payload) {
            if (state.currentBoard) // We use set because this property is absent when non public
                state.currentBoard.notificationSettings[payload.type] = payload.enable
        },
        SET_NEW_NAME(state, name) {
            if (state.currentBoard)
                state.currentBoard.name = name
        }
    },

    actions: {

        fetchBoards({ commit }) {
            return http.get('/boards').then(response => commit('FETCH_BOARDS', response.data.boards))
        },

        createBoard({ commit }, name) {
            return http.post('/boards', { name })
                .then(response => {
                    const board = response.data.board
                    addExtraParams(board)
                    commit('ADD_BOARD', board)
                    return board
                })
        },

        selectAllBoards({ getters, commit }) {
            commit('SELECT_ALL_BOARDS', getters)
        },

        toggleStarSingle({ commit }, p) {
            http.post(`/boards/${p.id}/star`, { star: p.star })
                .then(() => commit('TOGGLE_STAR', { id: p.id, star: p.star }))
        },

        toggleStarMultiple({ dispatch, getters }) {
            const star = !!getters.selectedBoards.find(b => !b.star)
            getters.selectedBoards.map(b => b.id).forEach(id => dispatch('toggleStarSingle', { id, star }))
        },

        sortBoards({ state, commit }, ids) {
            const equal = state.boards.map(b => b.id).every((el, i) => el === ids[i])
            if (!equal) {
                let body = { ids }
                http.post('/boards/sort', body).then(() => commit('SORT_BOARDS', ids))
            }
        },

        // Settings

        rename({ state, commit }, name) {
            http.post(`/boards/${state.currentBoard.id}/rename`, { name })
                .then(response => commit('SET_NEW_NAME', response.data.name))
        },

        deleteBoards({ commit }, idsToDelete) {
            return Promise.all(idsToDelete.map(id => http.delete(`/boards/${id}`)))
                .then(() => commit('REMOVE_BOARDS', idsToDelete))
        },

        togglePublic({ state, commit }, makePublic) {
            http.post(`/boards/${state.currentBoard.id}/public`, { makePublic })
                .then(response => commit('SET_PUBLIC_URL', response.data.publicUrl))
        },

        toggleNotificationSettings({ state, commit }, payload) {
            http.post(`/boards/${state.currentBoard.id}/notifications`, payload)
                .then(() => commit('SET_NOTIFICATIONS', payload))
        }

    }

}