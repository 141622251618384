<template>
  <div id="dashboard">
    <DashboardFilters/>
<!--    <fa :icon="['fad', 'ballot-check']"/>-->
    <div v-if="boards.length === 0" id="empty-board-list">You don't have any board yet.</div>
    <BoardList/>
  </div>
</template>

<script>
import BoardList from '@/components/dashboard/BoardList'
import DashboardFilters from '@/components/dashboard/DashboardFilters'
import { mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: { BoardList, DashboardFilters },
  computed: mapState({ boards: s => s.boards.boards })
}
</script>

<style lang="stylus">
#dashboard
  height 100%
  max-height 100%
  overflow-y scroll

  & > svg
    color c-primary
    font-size 2em
    margin 10px

#empty-board-list
  padding 50px
  text-align center
</style>