import http from '../http'

export default {

    namespaced: true,

    state: {
        comments: []
    },

    mutations: {
        FETCH_COMMENTS: (state, p) => state.comments = p.comments.reverse(),
        ADD_COMMENT: (state, comment) => state.comments.push(comment)
    },

    actions: {

        fetchComments({ commit }, entryId) {
            return http.get(`/entries/${entryId}/comments`)
                .then(response => commit('FETCH_COMMENTS', { entryId, comments: response.data.comments }))
        },

        postComment({ commit }, comment) {
            return http.post(`/entries/${comment.entryId}/comments`, { comment })
                .then(response => commit('ADD_COMMENT', response.data.comment))
        }

    }

}