<template>
  <li class="tile" :data-board-id="board.id" @click.exact="goToBoard">

    <img v-if="board.cover" class="cover" :src="`${repositoryUrl}/${board.id}/${board.cover}`"/>
    <fa class="empty" v-else :icon="['fad', 'image']" transform="shrink-12"/>

    <span class="name">{{ board.name }}</span>

    <i class="entry-count">{{ board.entryCount }} image{{ board.entryCount === 1 ? '' : 's' }}</i>

  </li>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SearchResultBoardTile',
  props: ['board'],
  computed: {
    ...mapState({ draggingAssets: s => s.uploads.draggingAssets }),
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL
  },
  methods: {
    goToBoard() {
      this.$router.push(`/board/${this.board.id}`)
    },
    toggleSelect() {
      this.$store.commit('boards/SELECT_BOARD', { id: this.board.id, selected: !this.board.selected })
    },
    toggleStar() {
      this.$store.dispatch('boards/toggleStarSingle', { id: this.board.id, star: !this.board.star })
    }
  }
}
</script>

<style lang="stylus" scoped>
.tile
  position relative
  display inline-block
  margin 20px
  padding: 10px 10px 10px
  color #333
  background white
  border 1px solid #ccc
  border-radius v-radius
  box-shadow v-shadow c-shadow
  user-select none
  cursor pointer

  .cover
    object-fit cover
    display block
    width 300px
    height 200px
    border-radius v-radius
    pointer-events none

  svg.empty
    object-fit cover
    display block
    width 300px
    height 200px
    border-radius v-radius
    pointer-events none
    background-color #E9EEF1
    color #BABDC1

  .name
    margin-top 10px
    font-size 1rem
    font-weight 600
    display block

  .entry-count
    color #999
    display block
    font-size .875rem
</style>
