<template>
  <div class="option" :class="{selected: selected}" @click="select">{{ option }}</div>
</template>

<script>
export default {
  name: 'TagSelectorOption',
  props: {
    option: String,
    selected: Boolean
  },
  methods: {
    select() {
      this.$emit('selected', this.option)
    }
  }
}
</script>

<style lang="stylus" scoped>
.option
  padding 5px
  cursor pointer
  border-radius 5px
  font-size .9em

  &:hover
    background-color c-primary-light
    color white

.selected
  background-color #f2ddff
  //color white
</style>