<template>
  <div id="resultEntries" class="search-page">
    <h1><span>Images that match your search (title or tags)</span></h1>
    <ol id="resultEntriesList" v-if="entries.length > 0">
      <SearchResultEntryTile v-for="entry in entries" :key="entry.id" :entry="entry"/>
    </ol>
    <div v-else class="empty">- No images</div>
  </div>
</template>

<script>
import SearchResultEntryTile from '@/components/search/global/SearchResultEntryTile'
import Wookmark from 'wookmark'

export default {
  name: 'SearchResultEntryList',
  components: { SearchResultEntryTile },
  props: ['entries'],
  mounted() {
    this.doWookmark()
  },
  watch: {
    entries() {
      this.$nextTick(() => this.doWookmark())
    }
  },
  methods: {
    doWookmark() {

      if (this.wookmark)
        this.wookmark.clear()

      if (this.entries.length > 0)
        this.wookmark = new Wookmark('#resultEntriesList', {
          align: 'left',
          offset: 30,
          verticalOffset: 20,
          outerOffset: 20,
          itemWidth: 312,
          autoResize: true,
          resizeDelay: 50
        })

    }
  }
}
</script>

<style lang="stylus" scoped>
#resultEntries

  #resultEntriesList
    width 100%
    position relative

  h1
    margin-bottom 0

  .empty
    margin-top 10px
    margin-bottom 40px
</style>