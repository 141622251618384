import Vue from 'vue'
import Vuex from 'vuex'
import search from './search'
import boards from './boards'
import entries from './entries'
import comments from './comments'
import sharing from './sharing'
import uploads from './uploads'
import plans from './plans'
import publicBoard from './publicBoard'
import http from '@/http'

Vue.use(Vuex)

export default new Vuex.Store({

    strict: process.env.NODE_ENV !== 'production',

    modules: {
        search,
        boards,
        entries,
        comments,
        sharing,
        uploads,
        plans,
        publicBoard
    },

    state: {
        user: undefined,
        subscription: undefined,
        plan: undefined,
        tags: [],
        notifications: [],
        modal: undefined,
        modalProps: undefined
    },

    getters: {},

    mutations: {
        SET_USER_DATA: (state, payload) => {
            state.user = payload.user
            state.subscription = payload.subscription
            state.plan = payload.plan
            state.tags = payload.tags
            state.notifications = payload.notifications.reverse()
        },
        SHOW_MODAL: (state, payload) => {
            // We use the name because otherwise we get a modification outside store error
            state.modal = payload.modal.name
            state.modalProps = payload.props
        },
        DISMISS_MODAL: (state, payload) => {
            state.modal = payload ? payload.modal.name : undefined
            state.modalProps = payload ? payload.props : undefined
        },
        CLEAR_NOTIFICATIONS: (state) => {
            state.notifications = []
        },
        SET_FULL_NAME: (state, payload) => {
            state.user.fullName = payload.fullName
        }
    },

    actions: {

        loadUserInfo({ commit }) {
            return http.get('/profile').then(response => {
                commit('SET_USER_DATA', response.data)
                commit('boards/FETCH_BOARDS', response.data.boards)
            })
        },

        changeFullName({ commit }, payload) {
            return http.patch('/user', payload).then(() => commit('SET_FULL_NAME', payload))
        },

        changePassword(context, payload) {
            return http.patch('/auth/password', payload)
        },

        fetchNotifications({ commit }) {
            return http.get('/notifications').then(response => commit('SET_NOTIFICATIONS', response.data.notifications))
        },

        clearNotifications({ commit }) {
            return http.delete('/notifications').then(() => commit('CLEAR_NOTIFICATIONS'))
        }

    }

})