<template>
  <div id="signin-container">
    <img id="logo" alt="Boardflow" :src="require('@/assets/images/boardflow-logo-white.svg')" width="150"/>
    <div id="panel">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SigninLayout'
}
</script>

<style lang="stylus">
@import '~@/assets/styles/login-form.styl'

body
  overflow-y auto

#signin-container
  display flex
  align-items flex-start
  justify-content center
  padding-bottom 100px
  min-height 100vh
  background linear-gradient(45deg, c-gradient-purple-dark, c-gradient-purple-light)

  #logo
    position fixed
    top 4em
    left 4em

  #panel
    width 450px
    margin-top 20vh
    background white
    border-radius 10px
    box-shadow 0 0 20px rgba(0, 0, 0, 0.5)

</style>