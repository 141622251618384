import http from '@/http'
import router from '@/router/router'
import { prepareEntries } from '@/utils'

export default {

    namespaced: true,

    state: {
        entries: [],
        currentEntry: undefined
    },

    getters: {
        selectedEntries: state => state.entries.filter(e => e.selected)
    },

    mutations: {
        FETCH_ENTRIES(state, entries) {
            state.entries = entries
        },
        PREV_ENTRY: state => {
            if (state.entries.length < 2) return
            let index = state.entries.indexOf(state.currentEntry)
            state.currentEntry = index === 0
                ? state.entries[state.entries.length - 1]
                : state.entries[index - 1]
            router.replace(`/board/${state.currentEntry.boardId}/entry/${state.currentEntry.id}`)
        },
        NEXT_ENTRY: state => {
            if (state.entries.length < 2) return
            let index = state.entries.indexOf(state.currentEntry)
            state.currentEntry = index === state.entries.length - 1
                ? state.entries[0]
                : state.entries[index + 1]
            router.replace(`/board/${state.currentEntry.boardId}/entry/${state.currentEntry.id}`)
        },
        CURRENT_ENTRY(state, id) {
            // maybe switch to index-based strategy
            state.currentEntry = id ? state.entries.find(e => e.id === id) : undefined
            this.state.comments.comments = [] // hack?
        },
        SELECT_ALL_ENTRIES(state, getters) {
            const s = state.entries.length !== getters.selectedEntries.length
            state.entries.forEach(e => e.selected = s)
        },
        SELECT_ENTRY(state, p) {
            state.entries.find(e => e.id === p.id).selected = p.selected
        },
        REMOVE_ENTRIES(state, payload) {
            if (payload.adjustCurrent) {
                let boardId = state.currentEntry.boardId
                let indexes = payload.ids.map(id => state.entries.findIndex(e => e.id === id))
                let index = Math.min(...indexes)
                indexes.forEach(idx => state.entries.splice(idx, 1))
                if (state.entries.length > 0)
                    state.currentEntry = state.entries[Math.max(0, index - 1)]
                else {
                    state.currentEntry = undefined
                    router.replace(`/board/${boardId}`)
                }
            } else
                state.entries.filter(e => payload.ids.includes(e.id)).forEach(e => state.entries.splice(state.entries.indexOf(e), 1))

        },
        EDIT_ENTRY(state, entry) {
            state.currentEntry.title = entry.title
            state.currentEntry.description = entry.description
            state.currentEntry.tags = entry.tags
        },
        SORT_ENTRIES(state, ids) {
            const ordering = {}
            ids.forEach((id, i) => ordering[ids[i]] = i)
            state.entries.sort(function(a, b) {
                return (ordering[a.id] - ordering[b.id])
            })
        }
        // ADD_ENTRY: (state, entry) => {
        //     state.entries.unshift(entry)
        // },
        // toggleEntryStar(state, id) {
        //     const b = state.entries.find(b => b.id === id)
        //     b.star = !b.star
        // },
        // starAllEntries(state) {
        //     const s = state.entries.length !== this.getters.starredEntries.length
        //     this.getters.selectedEntries.forEach(b => b.star = s)
        // }
    },

    actions: {
        fetchEntries({ commit }, boardId) {

            return http.get(`/boards/${boardId}`)
                .then(response => {
                    let entries = response.data.board.entries
                    prepareEntries(entries)
                    commit('FETCH_ENTRIES', entries)
                })

        },
        selectAllEntries({ getters, commit }) {
            commit('SELECT_ALL_ENTRIES', getters)
        },
        deleteEntries({ commit }, payload) {
            return Promise.all(payload.ids.map(id => http.delete(`/entries/${id}`)))
                .then(() => commit('REMOVE_ENTRIES', payload))
        },
        editEntry({ commit, state }, entry) {
            return http.patch(`/entries/${state.currentEntry.id}`, entry)
                .then(response => commit('EDIT_ENTRY', response.data))
        },
        copyEntries(context, payload) {
            return http.post('/entries/copy', payload)
        },
        moveEntries(context, payload) {
            return http.post('/entries/move', payload)
        },
        sortEntries({ state, commit, rootState }, ids) {

            const equal = state.entries.map(e => e.id).every((el, i) => el === ids[i])

            if (!equal) {
                let body = { ids, boardId: rootState.boards.currentBoard.id }
                http.post('/entries/sort', body).then(() => commit('SORT_ENTRIES', ids))
            }

        }

    }

}