<template>
  <modal title="Delete Board" class="modal-delete" icon="trash">
    You are about to delete {{ howMany }} entr{{ howMany > 1 ? 'ies' : 'y' }}.
    <button class="alert" @click.prevent="go">Delete</button>
  </modal>
</template>

<script>
import Modal from '../Modal'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DeleteEntryModal',
  components: { Modal },
  props: ['mode'],
  computed: {
    ...mapState({ currentEntry: s => s.entries.currentEntry }),
    ...mapGetters('entries', ['selectedEntries']),
    howMany() {
      return this.mode === 'selected' ? this.selectedEntries.length : 1
    }
  },
  methods: {
    go() {
      let ids = this.mode === 'selected' ? this.selectedEntries.map(e => e.id) : [this.currentEntry.id]
      this.$store.dispatch('entries/deleteEntries', { ids, adjustCurrent: this.mode === 'current' })
          .then(() => this.$store.dispatch('boards/fetchBoards'))
          .then(() => this.$store.commit('DISMISS_MODAL'))
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-delete
  .modal-body
    display flex
    flex-direction column

  button
    margin-top 20px
</style>