<template>
  <div>
    <section>
      <h1><span>Collaborators</span></h1>
      Collaborators...
    </section>
  </div>
</template>

<script>

export default {
  name: 'OptionsCollaborators'
}
</script>

<style lang="stylus" scoped>
</style>