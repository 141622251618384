<template>
  <modal title="Withdraw Invitation" class="modal-delete" icon="trash">
    <span v-if="isPending">Are you sure you want to withdraw the invitation for {{ sharing.email }}?</span>
    <span v-else>Are you sure you want to remove {{ sharing.email }} from this board?</span>
    <button class="alert" @click.prevent="go">Withdraw</button>
  </modal>
</template>

<script>
import Modal from '../Modal'

export default {
  name: 'WithdrawInvitationModal',
  components: { Modal },
  props: ['sharing'],
  computed: {
    isPending() {
      return this.sharing.pending
    }
  },
  methods: {
    go() {
      if (this.isPending)
        this.$store.dispatch('sharing/withdrawInvitation', this.sharing)
            .then(() => this.$store.commit('DISMISS_MODAL'))
      else
        this.$store.dispatch('sharing/deleteSharing', this.sharing)
            .then(() => this.$store.commit('DISMISS_MODAL'))
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-delete
  .modal-body
    display flex
    flex-direction column

  button
    margin-top 20px
</style>