import PublicLayout from '@/layouts/PublicLayout'
import PublicBoard from '@/views/PublicBoard'
import store from '@/store'

export default [
    {
        path: '/public/:boardId', name: 'PublicBoard', component: PublicBoard, meta: { layout: PublicLayout },
        beforeEnter: (to, from, next) =>
            store.dispatch('publicBoard/fetchPublicBoard', to.params.boardId).then(next)
    }
]