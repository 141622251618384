<template>
  <nav :class="{ active }">
    <h3>Filters</h3>
    <ol class="filters">
      <li>
        <BoardCheckbox label="Favorites" @change="toggleStarred"/>
      </li>
      <li>
        <BoardCheckbox label="Owned by me" @change="toggleOwned"/>
      </li>
      <li>
        <BoardCheckbox label="Shared with me" @change="toggleShared"/>
      </li>
    </ol>
    <h3>My boards ({{ boards.length }})</h3>
    <ol>
      <li v-for="board in boards" :key="board.id">
        <router-link :to="`/board/${board.id}`">{{ board.name }}</router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import BoardCheckbox from '@/components/form/BoardCheckbox'

export default {
  name: 'SideBar',
  components: { BoardCheckbox },
  data() {
    return {
      active: true
    }
  },
  computed: mapState({
    starred: s => s.boards.filter.starred,
    owned: s => s.boards.filter.owned,
    shared: s => s.boards.filter.shared,
    boards: s => s.boards.boards
  }),
  methods: {
    toggleStarred() {
      this.$store.commit('boards/SET_FILTER', { name: 'starred', value: !this.starred })
    },
    toggleOwned() {
      let value = !this.owned
      this.$store.commit('boards/SET_FILTER', { name: 'owned', value })
      if (value)
        this.$store.commit('boards/SET_FILTER', { name: 'shared', value: !value })
    },
    toggleShared() {
      let value = !this.shared
      this.$store.commit('boards/SET_FILTER', { name: 'shared', value })
      if (value)
        this.$store.commit('boards/SET_FILTER', { name: 'owned', value: !value })
    },
    mousemove(e) {
      if (!this.active && e.clientX < 100) {
        this.active = true
      } else if (this.active && e.clientX > 250) {
        this.active = false
      }
    }
  },
  created() {
    document.addEventListener('mousemove', this.mousemove)
  }
}
</script>

<style lang="stylus" scoped>
nav
  display flex
  flex-direction column
  position absolute
  left -250px
  top 122px
  width 250px
  height 50%
  padding: 10px
  background #C4ADE2
  border-radius 0 4px 4px 0
  box-shadow 0 0 5px 1px #ccc
  transition left .3s ease-in-out
  z-index 1000

  .checkbox
    margin-bottom 5px

  &.active
    left 0

  h3
    font-weight bold
</style>