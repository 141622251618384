<template>
  <div id="upload-overlay">
    Upload files in {{ currentBoard ? currentBoard.name : uploadTarget.length ? uploadTarget[0].name : 'in a new board' }}
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UploadOverlay',
  computed: mapState({ currentBoard: s => s.boards.currentBoard, uploadTarget: s => s.uploads.uploadTarget })
}
</script>

<style lang="stylus">

#upload-overlay
  position fixed
  display flex
  justify-content center
  align-items center
  top 0
  left 0
  right 0
  height 123px
  background-color c-primary
  color white
  font-weight 700
  z-index 10000

</style>