<template>
  <modal title="New Board" icon="plus">
    <form @submit.prevent="go">
      <input name="name" type="text" class="wide" required placeholder="Board name" ref="input"/>
      <button>Ok</button>
    </form>
  </modal>
</template>

<script>
import Modal from '../Modal'
import UpgradeModal from '@/components/modals/UpgradeModal'

export default {
  name: 'NewBoardModal',
  components: { Modal },
  methods: {
    go() {
      this.$store.dispatch('boards/createBoard', this.$refs.input.value)
          .then(() => this.$store.commit('DISMISS_MODAL'))
          .catch(() => this.$store.commit('DISMISS_MODAL', { modal: UpgradeModal }))
    }
  },
  mounted() {
    this.$refs.input.focus()
  }
}
</script>

<style lang="stylus" scoped>
form
  display flex
  flex-direction column
  align-items flex-end

button
  min-width 4em
  margin-top 20px
  text-transform uppercase
</style>