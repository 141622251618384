<template>
  <ol id="board-list" ref="list" @mousedown="onDown">
    <BoardTile v-for="board in filteredBoards" :key="board.id" :board="board" v-show="matchSearch(board.name)"/>
    <BoardPlaceholder ref="ph"/>
  </ol>
</template>

<script>
import BoardTile from './BoardTile'
import BoardPlaceholder from './BoardPlaceholder'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'BoardList',
  components: { BoardTile, BoardPlaceholder },
  computed: {
    ...mapState({ search: s => s.search.search }),
    ...mapGetters('boards', ['filteredBoards'])
  },
  methods: {

    matchSearch(name) {
      return name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
    },

    onDown: function(e) {

      if (!e.target.classList.contains('tile'))
        return

      document.addEventListener('mousemove', this.onMove)
      document.addEventListener('mouseup', this.onUp)

      this.draggedBoard = e.target

      this.dX = e.pageX
      this.dY = e.pageY

      return false

    },

    onMove: function(e) {

      if (this.dragging) {

        this.draggedBoard.style.top = (e.pageY - this.dY) + 'px'
        this.draggedBoard.style.left = (e.pageX - this.dX) + 'px'

      } else {

        if (Math.max(Math.abs(e.pageX - this.dX), Math.abs(e.pageY - this.dY)) > 20) {

          this.dragging = true

          this.dX = e.pageX - this.draggedBoard.offsetLeft + 20
          this.dY = e.pageY - this.draggedBoard.offsetTop + 20

          this.draggedBoard.classList.add('dragging')
          this.draggedBoard.style.top = (e.pageY - this.dY) + 'px'
          this.draggedBoard.style.left = (e.pageX - this.dX) + 'px'

          this.$refs.list.insertBefore(this.$refs.ph.$el, this.draggedBoard)
          this.$refs.ph.show(this.draggedBoard.offsetWidth, this.draggedBoard.offsetHeight)

          this.$refs.list.querySelectorAll('.tile:not(.dragging)')
              .forEach(el => el.addEventListener('mouseenter', this.movePlaceholder))

        }

      }

    },

    onUp: function() {

      document.removeEventListener('mousemove', this.onMove)
      document.removeEventListener('mouseup', this.onUp)

      if (this.dragging) {

        this.dragging = false

        this.$refs.list.insertBefore(this.draggedBoard, this.$refs.ph.$el)

        this.draggedBoard.classList.remove('dragging')
        this.draggedBoard.style.top = this.draggedBoard.style.left = 'auto'

        this.$refs.ph.hide()

        this.$refs.list.querySelectorAll('.tile')
            .forEach(el => el.removeEventListener('mouseenter', this.movePlaceholder))


        let ids = [...this.$refs.list.querySelectorAll('li.tile')].map(function(el) {
          return el.dataset.boardId
        })

        this.$store.dispatch('boards/sortBoards', ids)

      }

    },

    movePlaceholder: function(e) {
      let children = Array.from(this.$refs.list.querySelectorAll('.tile:not(.dragging), .tile-placeholder'))
      this.$refs.list.insertBefore(this.$refs.ph.$el,
          children.indexOf(this.$refs.ph.$el) > children.indexOf(e.target)
              ? e.target
              : e.target.nextSibling)
    }

  }
}
</script>

<style lang="stylus" scoped>

#board-list
  width 100%
  padding 50px
  position relative
  display flex
  flex-direction row
  flex-wrap wrap
  /*justify-content center*/
  align-content flex-start

</style>