<template>
  <ul id="entries" ref="list">
    <PublicEntryTile v-for="entry in entries" :key="entry.id" :entry="entry"/>
  </ul>
</template>

<script>
import Wookmark from 'wookmark'
import PublicEntryTile from '@/components/publicBoard/PublicEntryTile'

export default {
  name: 'PublicEntryList',
  components: { PublicEntryTile },
  mounted() {
    this.doWookmark()
  },
  props: ['entries'],
  watch: {
    entries() {
      this.$nextTick(() => this.doWookmark())
    }
  },
  methods: {

    doWookmark() {

      if (this.wookmark)
        this.wookmark.clear()

      this.wookmark = new Wookmark('#entries', {
        align: 'center',
        offset: 30,
        verticalOffset: 20,
        outerOffset: 20,
        itemWidth: 312,
        autoResize: true,
        resizeDelay: 50
      })

    }

  }
}
</script>

<style lang="stylus" scoped>
#entries
  width 100%
  position relative
</style>