<template>
  <div id="search-results">
    <h1>Your search: <span>{{ currentQuery }}</span></h1>
    <SearchResultBoardList :boards="boards"/>
    <SearchResultEntryList :entries="entries"/>
  </div>
</template>

<script>
import SearchResultBoardList from '@/components/search/global/SearchResultBoardList'
import SearchResultEntryList from '@/components/search/global/SearchResultEntryList'
import { mapState } from 'vuex'

export default {
  name: 'Search',
  components: { SearchResultBoardList, SearchResultEntryList },
  computed: {
    ...mapState({ currentQuery: s => s.search.currentQuery, results: s => s.search.results }),
    boards() {
      return this.results.boards
    },
    entries() {
      return this.results.entries
    }
  }
}
</script>

<style lang="stylus" scoped>
#search-results
  height 100%
  max-height 100%
  overflow-y scroll
  padding 40px

  h1
    margin-bottom 40px
    font-size 1.4em

    span
      font-style italic
      font-weight 700
</style>