<template>
  <header id="header">

    <img id="logo" :src="require('@/assets/images/boardflow-logo.svg')" width="180" height="25"
         @click="$router.push('/dashboard')"/>

    <div style="flex: 1"/>

    <SearchBar class="search"/>

    <div style="flex: 1"/>

    <Notifications/>

    <UserDropdown/>

  </header>
</template>

<script>
import SearchBar from '@/components/search/bar/SearchBar'
import UserDropdown from '@/components/header/UserDropdown'
import Notifications from '@/components/header/Notifications'

export default {
  name: 'TopHeader',
  components: { Notifications, UserDropdown, SearchBar }
}
</script>

<style lang="stylus">
#header
  display flex
  padding 10px 20px
  gap 20px
  justify-content center
  align-items center
  white-space nowrap

  .extension-nudge
    font-weight 600
    color c-primary

  .search
    flex 1

  #logo
    display block
    white-space nowrap
    cursor pointer
</style>