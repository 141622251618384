<template>
  <li class="tile-placeholder"/>
</template>

<script>
export default {
  name: 'BoardPlaceholder',
  methods: {
    show(w, h) {
      this.$el.style.display = 'inline'
      this.$el.style.width = w + 'px'
      this.$el.style.height = h + 'px'
    },
    hide() {
      this.$el.style.display = 'none'
    }
  }
}
</script>

<style lang="stylus" scoped>

.tile-placeholder
  display none
  margin 20px
  padding: 10px
  background-color rgba(0, 0, 0, .05)
  border-radius v-radius

</style>