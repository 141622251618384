<template>
  <div id="styleguide">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'StyleguideLayout'
}
</script>