import http from '@/http'
import { prepareEntries } from '@/utils'

export default {

    namespaced: true,

    state: {
        board: undefined,
        entries: [],
        currentEntry: undefined,
        notFound: false
    },

    mutations: {
        SET_BOARD: (state, board) => {
            state.board = board
            state.entries = board.entries
        },
        SET_NOT_FOUND: (state, notFound) => state.notFound = notFound,
        CURRENT_ENTRY: (state, id) => state.currentEntry = id ? state.entries.find(e => e.id === id) : undefined,
        PREV_ENTRY: state => {
            if (state.entries.length < 2) return
            let index = state.entries.indexOf(state.currentEntry)
            state.currentEntry = index === 0
                ? state.entries[state.entries.length - 1]
                : state.entries[index - 1]
        },
        NEXT_ENTRY: state => {
            if (state.entries.length < 2) return
            let index = state.entries.indexOf(state.currentEntry)
            state.currentEntry = index === state.entries.length - 1
                ? state.entries[0]
                : state.entries[index + 1]
        },
    },

    actions: {

        fetchPublicBoard({ commit }, boardId) {
            return http.get(`/public/${boardId}`)
                .then(response => {
                    let entries = response.data.board.entries
                    prepareEntries(entries)
                    commit('SET_BOARD', response.data.board)
                }).catch(e => {
                    if (e.response.status === 404)
                        commit('SET_NOT_FOUND', true)
                })
        }

    }

}