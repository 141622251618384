<template>
  <div class="tooltip">
    <div>
      <strong>Collaborator</strong>
      <ol>
        <li>
          <fa :icon="['fad', 'check']"/>
          upload and delete
        </li>
        <li>
          <fa :icon="['fad', 'check']"/>
          invite other people
        </li>
        <li>
          <fa :icon="['fad', 'check']"/>
          comment on images
        </li>
        <li>
          <fa :icon="['fad', 'check']"/>
          great for team members
        </li>
      </ol>
    </div>
    <br/>
    <div>
      <strong>Viewer</strong>
      <ol>
        <li>
          <fa :icon="['fad', 'times']" class="nope"/>
          upload and delete
        </li>
        <li>
          <fa :icon="['fad', 'times']" class="nope"/>
          invite other people
        </li>
        <li>
          <fa :icon="['fad', 'check']"/>
          comment on images
        </li>
        <li>
          <fa :icon="['fad', 'check']"/>
          great for clients
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleHelpPopup'
}
</script>

<style lang="stylus" scoped>
.tooltip
  display none
  position absolute
  margin-left 10px
  padding 10px
  border 1px solid c-primary-extra-light
  border-radius v-radius
  background-color white
  box-shadow v-shadow-soft c-shadow
  white-space nowrap
  z-index 1000

  svg
    margin-right 5px
    color green
    width 16px

    &.nope
      color c-alert
</style>