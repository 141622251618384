export const prepareEntries = entries => {

    const THUMB_WIDTH = 300

    entries.forEach(e => {

        e.selected = false

        e.thumb = {}
        if (e.dimension.width > THUMB_WIDTH) {
            let ratio = e.dimension.height / e.dimension.width
            e.thumb.width = THUMB_WIDTH
            e.thumb.height = parseInt(THUMB_WIDTH * ratio) // TODO: check roundings
        } else e.thumb = e.dimension

    })

    return entries

}