import AppLayout from '@/layouts/AppLayout'
import SingleEntry from '@/views/SingleEntry'
import SingleEntryTools from '@/components/singleEntry/SingleEntryTools'

export default [
    {
        path: '/board/:boardId/entry/:entryId', name: 'SingleEntry', component: SingleEntry,
        props: route => ({ hash: route.hash ? route.hash.substring(1) : undefined }),
        meta: { layout: AppLayout, toolbar: SingleEntryTools }
        // Navigation guards in component
    }
]