<template>
  <modal title="Upgrade required" icon="exclamation">
    You have reached the maximum number of boards included in the free plan.<br/><br/>
    Please upgrade to one of our premium plans to continue.
    <button @click="upgrade">Go to Plans</button>
  </modal>
</template>

<script>
import Modal from '../Modal'

export default {
  name: 'UpgradeModal',
  components: { Modal },
  methods: {
    upgrade() {
      this.$store.commit('DISMISS_MODAL')
      this.$router.push('/settings#plans')
    }
  }
}
</script>

<style lang="stylus" scoped>
button
  margin-top 20px
</style>