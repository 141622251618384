<template>
  <div id="notifications">

    <fa v-if="hasNotifications" :icon="['fad', 'bell-on']" class="on"/>
    <fa v-else :icon="['fad', 'bell']" title="You are all set"/>

    <div v-if="hasNotifications" id="notifications-dropdown">
      <ol>
        <component v-for="notification in notifications" :key="notification.ts" :notification="notification"
                   :is="notificationType(notification)"/>
      </ol>
      <div class="dismiss" @click="clearNotifications">Dismiss notifications</div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Notifications',
  computed: {
    ...mapState({ notifications: s => s.notifications }),
    hasNotifications() {
      return this.notifications.length > 0
    }
  },
  methods: {
    ...mapActions(['clearNotifications']),
    // sound() {
    //   new Audio(`/not${Math.ceil(2 * Math.random())}.mp3`).play()
    // },
    notificationType(notification) {
      let type = notification.type
          .split('_')
          .map(s => s.toLowerCase())
          .map(s => s.charAt(0).toUpperCase() + s.slice(1)).join('')
      return `Notification${type}`
    }
  }
}
</script>

<style lang="stylus">
#notifications
  position relative
  width 24px
  text-align center
  cursor pointer
  z-index 1000

  .dismiss
    padding-top 8px
    text-align center
    font-size 0.9rem
    border-top 1px solid c-primary
    color c-primary
    cursor pointer

  svg.on
    color c-primary

  &:hover #notifications-dropdown
    display flex
    text-align left

  #notifications-dropdown
    position absolute
    display none
    flex-direction column
    width 300px
    max-height 400px
    padding 10px
    right 0
    border 2px solid c-primary
    border-radius 5px
    background-color white
    cursor auto

    ol
      overflow-y auto
      overflow-x hidden
      scrollbar-color c-primary transparent
      scrollbar-width thin

      &::-webkit-scrollbar
        width 5px

      &::-webkit-scrollbar-thumb
        border 5px solid c-primary

    li
      margin-bottom 10px
      word-wrap break-word
      white-space pre-wrap
      word-break break-word
</style>