<template>
  <div class="entry-comments">
    <div v-if="currentBoard.owner.id === user.id">
      Your plan does not have comments enabled.<br/><br/>
      <button class="grow" @click="$router.push('/settings#plans')">Upgrade Plan</button>
    </div>
    <div v-else>
      This board belongs to an account without comments enabled.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EntryCommentsUnsupported',
  computed: mapState({ user: s => s.user, currentBoard: s => s.boards.currentBoard })
}
</script>

<style lang="stylus" scoped>
.entry-comments
  display flex
  flex-direction column
  height 100%
  padding 10px
</style>