import http from '../http'

export default {

    namespaced: true,

    state: {
        currentSharing: []
    },

    getters: {
        pending: state => state.currentSharing.filter(s => s.pending),
        accepted: state => state.currentSharing.filter(s => !s.pending)
    },

    mutations: {
        FETCH_SHARING: (state, sharing) => state.currentSharing = sharing,
        ADD_SHARING: (state, sharing) => state.currentSharing.push(sharing),
        REMOVE_INVITATION: (state, invitation) => state.currentSharing.splice(state.currentSharing.indexOf(invitation), 1)
    },

    actions: {

        fetchSharing({ commit, rootState }) {
            http.get(`/boards/${rootState.boards.currentBoard.id}/sharing`)
                .then(response => commit('FETCH_SHARING', response.data.sharing))
        },

        createSharing({ commit, rootState }, invitation) {
            return http.post(`/boards/${rootState.boards.currentBoard.id}/sharing`, invitation)
                .then(response => commit('ADD_SHARING', response.data.sharing))
        },

        withdrawInvitation({ commit }, invitation) {
            let data = {
                boardId: invitation.boardId,
                email: invitation.email
            }
            return http.delete('/invitation', { data })
                .then(() => commit('REMOVE_INVITATION', invitation))
        },

        deleteSharing({ commit, rootState }, invitation) {
            let data = {
                sharing: {
                    boardId: invitation.boardId,
                    resourceId: invitation.resourceId,
                    type: invitation.type
                }
            }
            return http.delete(`/boards/${rootState.boards.currentBoard.id}/sharing`, { data })
                .then(() => commit('REMOVE_INVITATION', invitation))
        }

    }

}