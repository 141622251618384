<template>
  <div v-if="entry" id="entry-info">
    <EditableTitle :entry="entry"/>
    <EntryDetails :entry="entry"/>
    <EditableDescription :entry="entry"/>
    <EditableTags :entry="entry"/>
    <SavedBy :entry="entry"/>
  </div>
</template>

<script>
import EditableTitle from '@/components/singleEntry/sidebar/info/EditableTitle'
import EntryDetails from '@/components/singleEntry/sidebar/info/EntryDetails'
import EditableDescription from '@/components/singleEntry/sidebar/info/EditableDescription'
import SavedBy from '@/components/singleEntry/sidebar/info/SavedBy'
import { mapState } from 'vuex'
import EditableTags from '@/components/singleEntry/sidebar/info/EditableTags'

export default {
  name: 'EntryInfo',
  components: { EditableTags, EntryDetails, EditableDescription, SavedBy, EditableTitle },
  computed: mapState({ entry: s => s.entries.currentEntry })
}
</script>

<style lang="stylus" scoped>
#entry-info
  padding 10px
</style>