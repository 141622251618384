<template>
  <li>
    <strong>{{ notification.actorName }}</strong> commented on
    <strong>
      <router-link :to="`/board/${this.notification.boardId}/entry/${this.notification.entryId}#comments`">
        {{ notification.entryName }}
      </router-link>
    </strong>
    <br/>
    <em>{{ notification.commentExcerpt }}</em>
  </li>
</template>

<script>
export default {
  name: 'NotificationNewComment',
  props: ['notification'],
  methods: {
    go() {
      this.$router.push()
    }
  }
}
</script>