<template>
  <div>
    <section>
      <h1><span>Notifications</span></h1>
      <fieldset>
        <BoardCheckbox :checked="currentBoard.notificationSettings.email"
                       label="Receive <strong>email</strong> notifications" @change="e => change('email', e)"/>
      </fieldset>
      <fieldset>
        <BoardCheckbox :checked="currentBoard.notificationSettings.app"
                       label="Receive <strong>in app</strong> notifications" @change="e => change('app', e)"/>
      </fieldset>
    </section>
  </div>
</template>

<script>
import BoardCheckbox from '@/components/form/BoardCheckbox'
import { mapState } from 'vuex'

export default {
  name: 'OptionsNotifications',
  components: { BoardCheckbox },
  computed: mapState({ currentBoard: s => s.boards.currentBoard }),
  methods: {
    change(type, enable) {
      this.$store.dispatch('boards/toggleNotificationSettings', { type, enable })
    }
  }
}
</script>