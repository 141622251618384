<template>
  <div>
    <ol>

      <ToolbarAction :label="entries.length !== selectedEntries.length ? 'Select All' : 'Unselect All'"
                     v-show="entries.length > 0"
                     icon="check-double" @action="selectAllEntries"/>

<!--      <ToolbarAction label="Favorite" icon="heart"
                     v-show="selectedEntries.length > 0"/>-->

      <ToolbarAction label="Copy" icon="copy"
                     v-show="selectedEntries.length > 0" @action="copy"/>

      <ToolbarAction label="Move" icon="random"
                     v-show="selectedEntries.length > 0" @action="move"/>

      <ToolbarAction label="Delete" icon="trash"
                     v-show="selectedEntries.length > 0" @action="deleteEntries"/>

      <li style="border-left: 1px solid lightgray">&nbsp;</li>

      <ToolbarAction label="Sharing" icon="users"
                     @action="$router.push({ path: 'settings#sharing', append: true })"/>

      <ToolbarAction label="Board Settings" icon="cog"
                     @action="$router.push({ path: 'settings', append: true })"/>

      <li>
        <button @click="$refs.input.click()">Upload</button>
        <input id="upload-input" type="file" multiple @change="fileSelected" ref="input"/>
      </li>

    </ol>
  </div>
</template>

<script>
import ToolbarAction from '../ToolBarAction'
import DeleteEntryModal from '@/components/modals/DeleteEntryModal'
import CopyMoveEntryModal from '@/components/modals/CopyMoveEntryModal'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SingleBoardTools',
  components: { ToolbarAction },
  data() {
    return {
      showSettings: false
    }
  },
  computed: {
    ...mapState({ entries: s => s.entries.entries }),
    ...mapGetters('entries', ['selectedEntries'])
  },
  methods: {
    ...mapActions('entries', ['selectAllEntries']),
    copy() {
      this.$store.commit('SHOW_MODAL', { modal: CopyMoveEntryModal, props: { action: 'copy', mode: 'selected' } })
    },
    move() {
      this.$store.commit('SHOW_MODAL', { modal: CopyMoveEntryModal, props: { action: 'move', mode: 'selected' } })
    },
    deleteEntries() {
      this.$store.commit('SHOW_MODAL', { modal: DeleteEntryModal, props: { mode: 'selected' } })
    },
    fileSelected(e) {
      if (e.target.files.length > 0)
        this.$store.dispatch('uploads/drop', { items: e.target.files, board: this.$store.state.boards.currentBoard })
    }
  }
}
</script>

<style lang="stylus" scoped>
ol
  display flex
  flex-direction row
  justify-content center
  gap 15px

#upload-input
  display none
</style>