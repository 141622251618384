<template>
  <modal :title="`${action} entries`" :icon="action === 'copy' ? 'copy' : 'random'">
    <div>
      {{ createMode ? 'Create' : 'Select' }} a target board where to {{ action }} {{ selectedEntries.length }}
      entr{{ selectedEntries.length === 1 ? 'y' : 'ies' }}<span v-if="filtered.length > 0">,<br/>
      or <span class="create" @click="createBoard">{{
          createMode ? 'select a board' : 'create a new board'
        }}</span></span>
    </div>
    <input v-show="createMode" type="text" placeholder="Board name" ref="input" @keydown.enter="go"/>
    <select v-show="!createMode" ref="select">
      <option v-for="board in filtered" :key="board.id" :value="board.id">{{ board.name }}</option>
    </select>
    <button :class="{loading: loading}" :disabled="loading" @click="go">{{ action }}</button>
  </modal>
</template>

<script>
import Modal from '../Modal'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CopyMoveEntryModal',
  components: { Modal },
  props: ['action', 'mode'],
  data() {
    return {
      loading: false,
      createMode: false
    }
  },
  computed: {
    filtered() {
      return this.boards.filter(b => b.id !== this.currentBoard.id)
    },
    ...mapState({
      boards: s => s.boards.boards,
      currentBoard: s => s.boards.currentBoard,
      currentEntry: s => s.entries.currentEntry
    }),
    ...mapGetters('entries', ['selectedEntries'])
  },
  methods: {
    go() {
      this.loading = true
      let action = this.action === 'copy' ? 'copyEntries' : 'moveEntries'
      let ids = this.mode === 'selected' ? this.selectedEntries.map(e => e.id) : [this.currentEntry.id]
      let boardPromise = this.createMode
          ? this.$store.dispatch('boards/createBoard', this.$refs.input.value).then(b => b.id)
          : Promise.resolve(this.$refs.select.value)
      boardPromise
          .then(targetId => this.$store.dispatch(`entries/${action}`, { ids, targetBoard: targetId }))
          .then(() => this.$store.dispatch('entries/fetchEntries', this.currentBoard.id))
          .then(() => this.$store.commit('DISMISS_MODAL'))
          .then(() => this.$store.dispatch('boards/fetchBoards'))
    },
    createBoard() {
      this.createMode = !this.createMode
      let el = this.createMode ? this.$refs.input : this.$refs.select
      this.$nextTick(() => el.focus())
    }
  },
  mounted() {
    this.createMode = this.filtered.length === 0
  }
}
</script>

<style lang="stylus" scoped>
button, select, input
  margin-top 20px

button
  text-transform capitalize

.create
  font-weight 600
  color c-primary
  cursor pointer
</style>