<template>
  <ol>
    <ToolbarAction label="Close" icon="times" @action="back"/>
  </ol>
</template>

<script>
import ToolbarAction from '@/components/ToolBarAction'
import { mapState } from 'vuex'

export default {
  name: 'BoardSettingsTools',
  components: { ToolbarAction },
  computed: mapState({ currentBoard: s => s.boards.currentBoard }),
  methods: {
    back() {
      this.$router.push(`/board/${this.currentBoard.id}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
ol
  display flex
  flex-direction row
  justify-content center
  gap 15px
</style>