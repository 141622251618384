<template>
  <li class="upload-task">
    <!--<img class="preview" :src="src"/>-->
    <div class="progress">
      <div class="file-name">{{ upload.entry.name }}</div>
      <div class="bar" :style="{width: progress + '%'}"></div>
    </div>
  </li>
</template>

<script>
import http from '../../http'
import { mapState } from 'vuex'

export default {
  name: 'UploadTask',
  inheritAttrs: false,
  props: ['upload'],
  computed: mapState({ runningUploads: s => s.uploads.runningUploads }),
  data() {
    return {
      started: false,
      progress: 0
      // src: undefined
    }
  },
  watch: {
    '$store.state.uploads.runningUploads': function() {
      this.maybeStart()
    }
  },
  methods: {

    maybeStart() {

      if (this.started || this.upload.state !== 'waiting' || this.runningUploads >= 2)
        return

      this.started = true
      this.$store.commit('uploads/RUN_UPLOAD', this.upload)

      if (this.upload.entry instanceof File)
        this.doUpload(this.upload.entry)
      else
        this.upload.entry.file(f => this.doUpload(f))

    },

    doUpload(file) {

      // Preview
      // let fr = new FileReader()
      // fr.onloadend = e => this.src = e.target.result
      // fr.readAsDataURL(file)

      let formData = new FormData()
      formData.append('file', file)
      formData.append('boardId', this.upload.boardId)
      formData.append('title', this.upload.entry.name)
      formData.append('feature', 'BROWSER')

      http.post('/upload', formData, { onUploadProgress: this.uploadProgress })
          .then(() => console.log('Upload success'), () => console.log('Upload error'))
          .finally(() => this.$store.commit('uploads/COMPLETE_UPLOAD', this.upload))

    },

    uploadProgress(e) {
      if (e.lengthComputable)
        this.progress = Math.round(e.loaded / e.total * 100)
    }

  },
  mounted() {
    this.maybeStart()
  }
}
</script>

<style lang="stylus" scoped>
.upload-task
  display block
  margin 20px 0
  padding 0 10px

  //&:last-child
  //  margin-bottom 0

  .progress

    .file-name
      font-size 1em
      white-space nowrap
      overflow hidden
      text-overflow ellipsis

    .bar
      background repeating-linear-gradient(
          45deg,
          #D89FE2,
          #D89FE2 10px,
          c-primary 10px,
          c-primary 20px
      )
      transition: width .5s ease-in-out
      height 2px
      border-radius 5px
      margin-top 5px

    .preview
      box-shadow 0 0 10px lightgrey
      float left
      display block
      width 100px
      height 75px
      object-fit cover
      border-radius 5px
</style>