<template>
  <li class="tile" :class="{selected: board.selected, highlighted: highlighted}" :data-board-id="board.id"
      @click.exact="goToBoard" @click.ctrl="toggleSelect"
      @dragenter="dragEnter" @dragleave="dragLeave" @drop.prevent.stop="drop" @dragover.prevent>

    <BoardCheckbox class="selector" :checked="board.selected" @change="toggleSelect"/>

    <img v-if="board.cover" class="cover" :src="`${repositoryUrl}/${board.id}/${board.cover}`"/>
    <fa class="empty" v-else :icon="['fad', 'image']" transform="shrink-12"/>

    <span class="name">{{ board.name }}</span>

    <i class="entry-count">{{ board.entryCount }} image{{ board.entryCount === 1 ? '' : 's' }}</i>
    <ul class="tools">
      <fa size="lg" :icon="[board.star ? 'fad' : 'far', 'heart']" @click.stop="toggleStar"/>
      <fa v-if="board.shared" size="lg" :icon="['fad', 'users']" title="This board is shared"
          @click.stop="$router.push(`/board/${board.id}/settings#sharing`)"/>
    </ul>

  </li>
</template>

<script>
import BoardCheckbox from '@/components/form/BoardCheckbox'
import { mapState } from 'vuex'

export default {
  name: 'BoardTile',
  components: { BoardCheckbox },
  props: ['board'],
  data() {
    return {
      highlighted: false
    }
  },
  computed: {
    ...mapState({ draggingAssets: s => s.uploads.draggingAssets }),
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL
  },
  methods: {
    goToBoard() {
      this.$router.push(`/board/${this.board.id}`)
    },
    toggleSelect() {
      this.$store.commit('boards/SELECT_BOARD', { id: this.board.id, selected: !this.board.selected })
    },
    toggleStar() {
      this.$store.dispatch('boards/toggleStarSingle', { id: this.board.id, star: !this.board.star })
    },
    // DND
    dragEnter() {
      if (this.draggingAssets && !this.highlighted) {
        this.highlighted = true
        this.$store.commit('uploads/UPLOAD_TARGET', this.board)
      }
    },
    dragLeave(e) {
      if (this.highlighted && !e.currentTarget.contains(e.relatedTarget)) {
        this.highlighted = false
        this.$store.commit('uploads/UPLOAD_TARGET', undefined)
      }
    },
    drop(e) {
      if (this.draggingAssets) {
        this.highlighted = false
        this.$store.dispatch('uploads/drop', { items: e.dataTransfer.items, board: this.board })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.tile.highlighted
  border-color c-primary
  box-shadow 0 0 8px rgba(155, 39, 176, .6)

.tile.selected
  border-color c-primary
  box-shadow 0 0 8px rgba(155, 39, 176, .6)

  .selector
    display block

.tile.dragging
  position absolute
  pointer-events none
  opacity .8
  z-index 10000

  .selector
    display none

  &::before
    display none

.tile
  position relative
  display inline
  margin 20px
  padding: 10px 10px 10px
  color #333
  background white
  border 1px solid #ccc
  border-radius v-radius
  box-shadow v-shadow c-shadow
  user-select none
  cursor pointer

  &::before,
  &::after
    position absolute
    content ''
    top 0
    left 0
    width 100%
    height 100%
    border-radius 10px
    box-shadow none
    z-index -10
    transition 0.25s all ease
    background white

  &:hover::before
    border none
    box-shadow v-shadow c-shadow
    transform rotate(5deg)

  &:hover::after
    border: none
    box-shadow v-shadow c-shadow
    transform rotate(-10deg)

  .cover
    object-fit cover
    display block
    width 300px
    height 200px
    border-radius v-radius
    pointer-events none

  svg.empty
    object-fit cover
    display block
    width 300px
    height 200px
    border-radius v-radius
    pointer-events none
    background-color #E9EEF1
    color #BABDC1

  .selector
    position absolute
    display none
    top 15px
    right 15px
    margin 0

  &:hover > .selector
    display block

  .name
    margin-top 10px
    font-size 1rem
    font-weight 600
    display block
    max-width 300px
    text-ellipsis()

  .entry-count
    color #999
    display block
    font-size .875rem

  .tools
    display flex
    justify-content space-between;
    align-items center;
    margin 10px 0 0
    color c-primary

</style>
