<template>
  <li>
    <slot/>
  </li>
</template>

<script>
export default {
  name: 'SettingsListItem'
}
</script>

<style lang="stylus" scoped>
li
  padding 10px
  cursor pointer
  border-left 2px solid white

  &:hover, &.active
    border-left 2px solid c-primary
</style>