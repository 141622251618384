<template>
  <div v-if="entry" id="big-image" ref="big">
    <img :src="`${repositoryUrl}/${entry.boardId}/${entry.previewRepoKey}`" :class="{zoomed}"
         @click="zoom" ref="img"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BigImage',
  inheritAttrs: false,
  data: () => {
    return {
      zoomed: false
    }
  },
  methods: {
    zoom(e) {
      this.zoomed = !this.zoomed
      if (this.zoomed) {
        const percentX = e.offsetX * 100 / this.$refs.img.offsetWidth
        const percentY = e.offsetY * 100 / this.$refs.img.offsetHeight
        const targetX = this.entry.dimension.width * percentX / 100
        const targetY = this.entry.dimension.height * percentY / 100
        this.$nextTick(() => {
          this.$refs.big.scrollLeft = targetX - e.offsetX
          this.$refs.big.scrollTop = targetY - e.offsetY
        })
      }
    }
  },
  computed: {
    ...mapState({ entry: s => s.entries.currentEntry }),
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL
  }
}
</script>

<style lang="stylus" scoped>
#big-image
  height 100%
  overflow-y auto
  padding 50px 20px 200px 20px
  margin-right 350px
  cursor zoom-in

  img
    display block
    object-fit fill
    margin 0 auto
    box-shadow 0 0 5px 1px #ccc

    &.zoomed
      object-fit none
      max-width none
      cursor zoom-out
</style>