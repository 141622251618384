import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=3b323473&scoped=true"
import script from "./Payment.vue?vue&type=script&lang=js"
export * from "./Payment.vue?vue&type=script&lang=js"
import style0 from "./Payment.vue?vue&type=style&index=0&id=3b323473&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.88.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b323473",
  null
  
)

export default component.exports