import http from '@/http'
import router from '@/router/router'
import { prepareEntries } from '@/utils'

export default {

    namespaced: true,

    state: {
        search: '',
        currentQuery: '',
        suggestions: [],
        results: {
            boards: [],
            entries: []
        }
    },

    mutations: {
        RESET_SEARCH: state => {
            state.search = ''
            state.suggestions = []
        },
        UPDATE_SEARCH: (state, query) => state.search = query,
        SET_CURRENT_QUERY: (state, query) => state.currentQuery = query,
        NEXT_RESULT: state => {
            if (state.suggestions.length === 0) return
            let selectedIdx = state.suggestions.findIndex(r => r.selected)
            if (selectedIdx >= 0) {
                state.suggestions[selectedIdx].selected = false
                state.suggestions[(selectedIdx + 1) % state.suggestions.length].selected = true
            } else state.suggestions[0].selected = true
        },
        PREV_RESULT: state => {
            if (state.suggestions.length === 0) return
            let selectedIdx = state.suggestions.findIndex(r => r.selected)
            if (selectedIdx >= 0) {
                state.suggestions[selectedIdx].selected = false
                state.suggestions[(selectedIdx > 0 ? selectedIdx : state.suggestions.length) - 1].selected = true
            } else state.suggestions[state.suggestions.length - 1].selected = true
        },
        SET_SUGGESTIONS: (state, suggestions) => state.suggestions = suggestions,
        SET_RESULTS: (state, results) => state.results = results,
        SELECT_ENTRY(state, p) {
            state.results.entries.find(e => e.id === p.id).selected = p.selected
        }
    },

    actions: {

        searchRemote({ commit }, query) {
            commit('SET_CURRENT_QUERY', query)
            return http.post('/search', { query })
                .then(response => {
                    prepareEntries(response.data.entries)
                    commit('SET_RESULTS', response.data)
                })
        },

        searchLocal({ commit, dispatch, rootState }, query) {

            commit('UPDATE_SEARCH', query)
            query = query.trim().toLowerCase()
            let suggestions = []
            if (query) {

                let boards = rootState.boards.boards
                    .filter(b => b.name.toLowerCase().includes(query))
                    .map((b, i) => ({
                        id: i,
                        type: 'BOARD',
                        label: b.name,
                        action: () => router.push(`/board/${b.id}`),
                        selected: false
                    }))

                let tags = rootState.tags
                    .filter(t => t.includes(query))
                    .map((t, i) => ({
                        id: i,
                        type: 'TAG',
                        label: t,
                        action: () => router.push(`/tags/${t}`),
                        selected: false
                    }))

                let actions = [{
                    id: 1,
                    type: 'ACTION',
                    label: 'Search Everywhere...',
                    action: () => dispatch('searchRemote', query)
                        .then(() => {
                            if (router.currentRoute.name !== 'Search' || router.currentRoute.query.q !== query)
                                router.push({ path: '/search', query: { q: query } })
                        }),
                    selected: false
                }]

                suggestions = boards.concat(tags.concat(actions))

            }

            commit('SET_SUGGESTIONS', suggestions)

        },

        goToResult({ commit, state }, result) {
            if (state.suggestions.length === 0) return
            result = result || state.suggestions.find(r => r.selected) || state.suggestions.find(r => r.type === 'ACTION')
            if (!result) return
            result.action()
            commit('RESET_SEARCH')
        }

    }

}