<template>
  <li class="plan">
    <h3>{{ plan.label }}</h3>
    <div class="price">

      <div class="monthly">
        <span class="currency">$</span>
        <span class="amount">{{ yearAmount }}</span>
      </div>

      <div v-if="payAnnually || !plan.hasMonthly" class="interval">
        billed <strong>annually</strong>
        <span v-if="plan.hasMonthly"> ({{ discount }}% off)</span>
      </div>
      <div v-else class="interval">
        billed <strong>${{ monthAmount }} monthly</strong>
      </div>

    </div>

    <ul>

      <li v-if="plan.features.includes('UNLIMITED_BOARDS')">
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <span><strong>Unlimited</strong> boards</span>
      </li>
      <li v-else>
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <span><strong>{{ plan.maxBoards }}</strong> boards</span>
      </li>

      <li v-if="plan.features.includes('UNLIMITED_STORAGE')">
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <span><strong>Unlimited</strong> storage</span>
      </li>
      <li v-else>
        <fa :icon="['fad', 'check-circle']"/>
        <span><strong>Limited</strong> storage</span>
      </li>

      <li v-if="plan.features.includes('UNLIMITED_COLLABORATORS')">
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <span><strong>Unlimited</strong> collaborators</span>
      </li>

      <li v-if="plan.features.includes('USER_MANAGEMENT_ADVANCED')">
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <span><strong class="with-info">Advanced</strong> user management</span>
      </li>
      <li v-else-if="plan.features.includes('USER_MANAGEMENT_BASIC')">
        <fa :icon="['fad', 'check-circle']"/>
        <span><strong class="with-info">Basic</strong> user management</span>
      </li>

      <li v-if="plan.features.includes('PUBLIC_BOARDS')">
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <strong>Public boards</strong>
      </li>

      <li v-if="plan.features.includes('COMMENTS')">
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <strong>Comments</strong>
      </li>
      <li>
        <fa :icon="['fad', 'check-circle']" :class="{ yes: plan.features.includes('SUPPORT_PRIORITY') }"/>
        <span><strong>{{ plan.features.includes('SUPPORT_PRIORITY') ? 'Priority' : 'Regular' }}</strong> support</span>
      </li>
      <li v-if="plan.features.includes('FUTURE_FEATURES')">
        <fa :icon="['fad', 'check-circle']" class="yes"/>
        <em>All future features!</em>
      </li>
    </ul>

    <button v-if="isCurrentUserPlan" class="grow" disabled>
      Your plan
    </button>
    <button v-else class="grow" :disabled="checkoutStarted || isCurrentUserPlan" @click="choose">
      {{
        checkoutStarted ? 'Please wait' : `Choose ${plan.label} (${payAnnually || !plan.hasMonthly ? 'annually' : 'monthly'})`
      }}
    </button>

  </li>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Plan',
  props: ['plan', 'payAnnually', 'checkoutStarted'],
  computed: {
    ...mapState({ userPlan: s => s.plan }),
    monthAmount() {
      return (this.payAnnually ? this.plan.annualAmount / 12 : this.plan.monthlyAmount) / 100
    },
    yearAmount() {
      let monthlyAmount = this.plan.hasMonthly ? this.plan.monthlyAmount * 12 : this.plan.annualAmount
      return (this.payAnnually ? this.plan.annualAmount : monthlyAmount) / 100
    },
    discount() {
      return Math.round(100 - (100 * this.plan.annualAmount / (this.plan.monthlyAmount * 12)))
    },
    isCurrentUserPlan() {
      return this.payAnnually ? this.userPlan.name === this.plan.annualPlan : this.userPlan.name === this.plan.monthlyPlan
    }
  },
  methods: {
    choose() {
      this.$emit('choose', this.payAnnually // poor man's coalesce
          ? this.plan.annualPlan || this.plan.monthlyPlan
          : this.plan.monthlyPlan || this.plan.annualPlan)
    }
  }
}
</script>

<style lang="stylus" scoped>
li.plan
  display flex
  flex-direction column
  border 1px solid lightgrey
  padding 20px
  min-width 300px
  border-radius 5px

  h3
    font-size 1.8em
    font-weight 700
    text-align center
    text-shadow -1px 1px 0px #cecece

  button
    margin 30px 0 0 0

  .price
    text-align center

    .monthly
      line-height 1.5

      .amount
        font-size 4em
        font-weight 700
        text-shadow -1px 1px 5px #cecece

      .currency
        font-size 1.8em

    .interval
      font-size .9em

  ul
    flex 1
    margin-top 2em
    text-align left

    li
      display flex
      flex-direction row
      align-items center
      padding 5px 0
      border none

      .with-info
        text-underline-offset 3px
        text-decoration underline dashed
        text-decoration-thickness 1px

      svg
        font-size 1.6em
        margin-right .5em
        color darkgray

        &.yes
          color c-primary
</style>