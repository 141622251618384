<template>
  <li class="tool" @click="$emit('action')">
    <fa :icon="['fad', icon]"/>
    <span>{{ label }}</span>
  </li>
</template>

<script>
export default {
  name: 'ToolbarAction',
  props: ['label', 'icon']
}
</script>

<style lang="stylus" scoped>
.tool
  display flex
  flex-direction column
  align-content center
  align-items center
  cursor pointer

  span
    font-size .9em
    line-height 18px

  svg
    display block
    margin-bottom 4px
    color #555

  &:hover svg
    color c-primary
</style>