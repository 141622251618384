<template>
  <div>
    <section v-if="owned">
      <h1><span>Rename Board</span></h1>
      <div class="rename">
        <input type="text" :value="currentBoard.name" placeholder="Board name" ref="name" @keydown.enter="renameBoard">
        <button @click="renameBoard">Rename</button>
      </div>
    </section>
    <section>
      <h1><span>Delete Board</span></h1>
      <div class="delete">
        <span v-if="owned">
          Are you sure? You own this board, deleting it will make it unavailable to any collaborator.
        </span>
        <span v-else>Are you sure? You will be leaving this board as a collaborator.</span>
        <button class="alert" @click="deleteBoard">{{ owned ? 'Delete' : 'Leave' }}</button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OptionsBasic',
  computed: {
    owned() {
      return this.user.id === this.currentBoard.owner.id
    },
    ...mapState({ user: s => s.user, currentBoard: s => s.boards.currentBoard })
  },
  methods: {
    renameBoard() {
      let value = this.$refs.name.value.trim()
      if (value)
        this.$store.dispatch('boards/rename', value)
    },
    deleteBoard() {
      this.$store.dispatch('boards/deleteBoards', [this.currentBoard.id]).then(() => this.$router.replace('/dashboard'))
    }
  }
}
</script>

<style lang="stylus" scoped>
.delete
  display flex
  align-items center
  gap 50px

  span
    flex 1

.rename
  display flex

  input
    flex 1

  button
    margin-left 10px
</style>