import SigninLayout from '@/layouts/SigninLayout'
import Invitation from '@/views/Invitation'

export default [
    {
        path: '/invitation/code/:code',
        name: 'Invitation',
        component: Invitation,
        props: true,
        meta: { layout: SigninLayout }
    }
]