<template>
  <div class="result" :class="{selected: result.selected}" @mousedown="go">{{ result.label }}</div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: ['result'],
  methods: {
    go() {
      this.$store.dispatch('search/goToResult', this.result)
    }
  }
}
</script>

<style lang="stylus" scoped>
.result
  padding 10px
  cursor pointer
  border-radius 5px

  &:hover
    background-color c-primary-light
    color white

.selected
  background-color c-primary-light
</style>