<template>
  <main ref="main">

    <section class="exclude">
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
      dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
      kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
      sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
      sanctus est Lorem ipsum dolor sit amet.
    </section>

    <section>
      <fieldset>
        <label>Text</label>
        <input type="text" placeholder="Some text"/>
      </fieldset>
      <fieldset>
        <label>Password</label>
        <input type="password" placeholder="Some text"/>
      </fieldset>
      <fieldset>
        <label>Email (wide)</label>
        <input type="email" class="wide" placeholder="Some text"/>
      </fieldset>
    </section>

    <section>
      <label>Textarea</label>
      <textarea placeholder="Some text" rows="5"/>
    </section>

    <section>
      <label>Button</label>
      <button>Click me</button>
      <br/>
      <button class="loading">Click me</button>
    </section>

    <section>
      <button class="small">Save</button>
      <br/>
      <button class="small alert">Save</button>
    </section>

    <section>
      <label>A select input field</label>
      <select>
        <option>Option 1</option>
        <option>Option 2</option>
      </select>
    </section>

    <section>
      <form>
        <label>Pick one</label>
        <select>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
        <label for="asd">Pick one</label>
        <input type="text" id="asd"/>
      </form>
    </section>

    <section>
      <BoardCheckbox label="Click here"/>
    </section>

  </main>
</template>

<script>
import BoardCheckbox from '@/components/form/BoardCheckbox'

export default {
  name: 'Styleguide',
  components: { BoardCheckbox }
  // mounted() {
  //   this.$refs.main.childNodes.forEach(c => {
  //     let code = document.createElement('code')
  //     code.innerText = c.innerHTML
  //     c.prepend(code)
  //   })
  // }
}
</script>

<style lang="stylus" scoped>
main
  padding 50px

  section
    padding 20px 0
    border-bottom 1px solid gray

  form
    padding 10px
    border 1px solid c-primary
</style>
<style lang="stylus">
code
  display inline-block
  padding 10px
  margin-bottom 20px
  border-radius 10px
  background-color whitesmoke
</style>