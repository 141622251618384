<template>
  <li class="entry-tile" :class="{ selected: entry.selected }" :data-entry-id="entry.id"
      @click.exact="goToEntry">
    <div class="label">
      <span class="title">{{ entry.title }}</span>
    </div>
    <img :src="`${repositoryUrl}/${entry.boardId}/${entry.thumbRepoKey}`" :width="entry.thumb.width" :height="entry.thumb.height"/>
    <ol v-if="entry.tags && entry.tags.length > 0" class="tags">
      <li v-for="tag in entry.tags" :key="tag">{{ tag }}</li>
    </ol>
  </li>
</template>

<script>
export default {
  name: 'SearchResultEntryTile',
  props: ['entry', 'search', 'publicMode'],
  computed: {
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL
  },
  methods: {
    goToEntry() {
      this.$router.push(`/board/${this.entry.boardId}/entry/${this.entry.id}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.entry-tile
  position relative
  display inline-block
  padding 5px
  min-width 312px
  background white
  border 1px solid #ccc
  border-radius 5px
  box-shadow 0 0 8px rgba(0, 0, 0, 0.15)
  user-select none
  cursor pointer

  .label
    display none
    position absolute
    padding 10px
    top 5px
    left 5px
    right 5px
    background rgba(255, 255, 255, .9)
    font-size .9em
    align-items flex-start
    gap 10px

    span
      flex 1
      font-weight 700
      display -webkit-box
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      overflow hidden

  ol.tags
    display flex
    margin-top 5px
    flex-wrap wrap
    gap 8px
    cursor pointer

    li
      padding 1px 5px
      max-width 100%
      font-size .8em
      background #f2ddff
      border 1px solid c-primary
      border-radius 3px
      overflow hidden
      text-overflow ellipsis

  &:hover
    .label
      display flex

  img
    display block
    max-width 300px
    margin 0 auto
    pointer-events none

</style>