<template>
  <div class="checkbox" @click.stop="click">
    <input type="checkbox" :checked="status" :required="required" v-bind="$attrs"/>
    <label>
      <fa :icon="['fas', 'check']" transform="grow-2"/>
    </label>
    <span v-if="label" class="text" v-html="label"></span>
    <span v-else-if="$slots.default" class="text">
      <slot/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BoardCheckbox',
  props: ['label', 'checked', 'required'],
  inheritAttrs: false,
  data() {
    return {
      status: false
    }
  },
  watch: {
    checked() {
      this.status = this.checked
    }
  },
  methods: {
    click() {
      this.status = !this.status
      this.$emit('change', this.status)
    }
  },
  mounted() {
    this.status = this.checked
  }
}
</script>

<style lang="stylus">
.checkbox
  position relative
  display flex
  height 24px
  cursor pointer

  input
    position absolute
    display block
    width 24px
    height 24px
    opacity 0
    z-index -1000

    &:checked + label > svg
      display inline

  label
    display inline-block
    width 24px
    height 24px
    margin 0
    border 1px solid c-primary
    border-radius v-radius
    background white
    box-shadow v-form-shadow
    text-align center
    cursor pointer

    svg
      margin-top 4px
      display none
      color c-primary

  span
    padding-left 10px
</style>