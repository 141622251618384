<template>
  <div id="crumbs">

    <router-link v-if="$route.name !== 'Dashboard'" to="/dashboard">
      <fa :icon="['fad', 'home']"/>
      <span>Home</span>
    </router-link>
    <div v-else>
      <fa :icon="['fad', 'home']"/>
      <span>Home</span>
    </div>

    <router-link v-if="currentEntry || $route.name === 'BoardSettings'" :to="`/board/${currentBoard.id}`">
      <fa icon="caret-right" class="caret"/>
      <span>{{ currentBoard.name }}</span>
      <fa v-if="currentBoard.shared" :icon="['fad', 'users']" style="margin-right: .8em"/>
    </router-link>
    <div v-else-if="currentBoard">
      <fa icon="caret-right" class="caret"/>
      <span>{{ currentBoard.name }}</span>
      <fa v-if="currentBoard.shared" :icon="['fad', 'users']" style="margin-right: .8em"/>
    </div>

    <div v-if="currentEntry">
      <fa icon="caret-right"/>
      <span>{{ currentEntry.title }}</span>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BreadCrumbs',
  computed: mapState({
    currentBoard: s => s.boards.currentBoard,
    currentEntry: s => s.entries.currentEntry
  })
}
</script>

<style lang="stylus">
#crumbs
  display flex
  flex-direction row
  align-items center
  justify-content flex-start

  a
    color c-primary
    text-decoration none

    span
      font-weight 600

  svg
    color #555

    &.caret
      color #777

  span
    margin 0 8px
    font-weight 600
</style>