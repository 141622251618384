<template>
  <li>
    New entries uploaded to <strong><router-link :to="`/board/${notification.boardId}/entry/${notification.entryId}`">{{ notification.boardName }}</router-link></strong>
  </li>
</template>

<script>
export default {
  name: 'NotificationNewEntry',
  props: ['notification']
}
</script>