<template>
  <main id="public-layout">
    <div id="header">
      <a id="logo" href="https://www.boardflow.co" target="_blank">
        <img :src="require('@/assets/images/boardflow-logo.svg')" width="180" height="25"/>
      </a>
    </div>
    <slot/>
  </main>
</template>

<script>
export default {
  name: 'PublicLayout'
}
</script>

<style lang="stylus">

#public-layout
  display flex
  flex-direction column
  height 100%

  #header
    padding 20px
    justify-content left

</style>