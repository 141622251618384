import UpgradeModal from '@/components/modals/UpgradeModal'

export default {

    namespaced: true,

    state: {
        draggingAssets: false,
        uploadTarget: [], // Implemented as a fifo queue because events could arrive out of order
        uploads: [],
        runningUploads: 0
    },

    mutations: {
        DRAGGING_ASSETS: (state, value) => state.draggingAssets = value,
        UPLOAD_TARGET: (state, value) => {
            if (value) state.uploadTarget.push(value)
            else state.uploadTarget.shift()
        },
        ADD_UPLOADS: (state, uploads) => state.uploads.push(...uploads),
        CLEAR_UPLOADS: (state) => state.uploads = state.uploads.filter(u => u.state !== 'finished'),
        RUN_UPLOAD: (state, upload) => {
            upload.state = 'running'
            state.runningUploads++
        },
        COMPLETE_UPLOAD: (state, upload) => {
            upload.state = 'finished'
            state.runningUploads--
        }
    },

    actions: {

        async drop({ commit, dispatch }, payload) {

            commit('DRAGGING_ASSETS', false)
            commit('UPLOAD_TARGET', undefined)

            let boardId = payload.board ? payload.board.id : undefined

            const { files, firstDirName } =
                payload.items instanceof DataTransferItemList
                    ? await dataTransferToFileEntries(payload.items)
                    : fileListToFileEntries(payload.items)

            if (!boardId) {

                let name = firstDirName || 'New board'
                let newBoard = await dispatch('boards/createBoard', name, { root: true })
                    .catch(() => commit('SHOW_MODAL', { modal: UpgradeModal }, { root: true }))

                if (!newBoard)
                    return

                boardId = newBoard.id

            }

            let hash = Date.now()
            const uploads = files.map((entry, i) => {
                return {
                    id: hash + '-' + (i + 1),
                    entry,
                    boardId,
                    state: 'waiting'
                }
            })

            commit('ADD_UPLOADS', uploads)

        }

    }

}

const acceptedFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff', 'tif', 'heic', 'heif', 'psd', 'pdf', 'jfif']

function fileListToFileEntries(items) {
    return { files: Array.from(items) }
}

async function dataTransferToFileEntries(items) {

    let files = []
    let firstDirName = undefined

    let queue = [] // Breadth first search
    for (let el of items)
        queue.push(el.webkitGetAsEntry())

    if (queue[0].isDirectory) // Get first directory name, useful to create board
        firstDirName = queue[0].name

    while (queue.length > 0) {

        let entry = queue.shift()

        if (entry.isFile && isAcceptable(entry))
            files.push(entry)
        else if (entry.isDirectory)
            queue.push(...await readDirEntries(entry.createReader()))

    }

    return { files, firstDirName }

}

async function readDirEntries(reader) {

    let dirEntries = []
    let currentEntries = []

    do {

        currentEntries = await new Promise((resolve, reject) => {
            reader.readEntries(resolve, reject)
        })

        dirEntries.push(...currentEntries)

    } while (currentEntries.length > 0) // readEntries return at most 100 entries, can stop when empty

    return dirEntries

}

function isAcceptable(entry) {
    let tokens = entry.name.split('.')
    return entry.name !== '.DS_Store' && acceptedFileTypes.includes(tokens[tokens.length - 1].toLowerCase())
}