<template>
  <main id="app-layout" @dragenter="dragEnter" @dragleave="dragLeave" @drop.prevent.stop="drop" @dragover.prevent>
    <TopHeader/>
    <!--<SideBar/>-->
    <ToolBar/>
    <slot/>
    <UploadOverlay v-show="draggingAssets"/>
    <UploadBox/>
    <Component :is="modal" v-bind="modalProps"/>
  </main>
</template>

<script>
import TopHeader from '@/components/header/TopHeader'
import ToolBar from '@/components/ToolBar'
import UploadOverlay from '@/components/upload/UploadOverlay'
import UploadBox from '@/components/upload/UploadBox'
import SideBar from '@/components/SideBar'
import { mapState } from 'vuex'

export default {
  name: 'AppLayout',
  components: { UploadBox, SideBar, UploadOverlay, TopHeader, ToolBar },
  computed: mapState({
    modal: s => s.modal, modalProps: s => s.modalProps,
    runningUploads: s => s.uploads.runningUploads,
    draggingAssets: s => s.uploads.draggingAssets
  }),
  methods: {
    dragEnter(e) {
      if (!this.draggingAssets && e.dataTransfer && e.dataTransfer.types.indexOf('Files') >= 0) {
        // console.log('main enter')
        this.$store.commit('uploads/DRAGGING_ASSETS', true)
      }
    },
    dragLeave(e) {
      if (this.draggingAssets && !e.currentTarget.contains(e.relatedTarget)) {
        // console.log('main leave')
        this.$store.commit('uploads/DRAGGING_ASSETS', false)
      }
    },
    drop(e) {
      if (this.draggingAssets) {
        // console.log('main drop')
        this.$store.dispatch('uploads/drop', {
          items: e.dataTransfer.items,
          board: this.$store.state.boards.currentBoard
        })
        //return false
      }
    }
  }
}
</script>

<style lang="stylus">

#app-layout
  display flex
  flex-direction column
  height 100%

</style>