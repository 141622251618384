<template>
  <div>
    <ol>

      <ToolbarAction label="Prev" icon="chevron-double-left" @action="prev"/>

      <ToolbarAction label="Next" icon="chevron-double-right" @action="next"/>

      <!--<ToolbarAction label="Favorite" icon="heart"/>-->

      <ToolbarAction label="Download" icon="download" @action="$refs.download.click()"/>
      <a id="download-entry" ref="download" v-if="currentEntry"
         :href="`${repositoryUrl}/${this.currentEntry.boardId}/${this.currentEntry.originalRepoKey}`"
         :download="downloadName"/>

      <li style="border-left: 1px solid lightgray">&nbsp;</li>

      <ToolbarAction label="Delete" icon="trash" @action="deleteEntry"/>

    </ol>
  </div>
</template>

<script>
import ToolbarAction from '@/components/ToolBarAction'
import DeleteEntryModal from '@/components/modals/DeleteEntryModal'
import { mapState } from 'vuex'

export default {
  name: 'SingleEntryTools',
  components: { ToolbarAction },
  data: () => {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapState({ currentEntry: s => s.entries.currentEntry }),
    repositoryUrl: () => process.env.VUE_APP_REPOSITORY_URL,
    downloadName() {
      return this.currentEntry.title.toLowerCase().endsWith(`.${this.currentEntry.type.toLowerCase()}`)
          ? this.currentEntry.title
          : `${this.currentEntry.title}.${this.currentEntry.type.toLowerCase()}`
    }
  },
  methods: {
    deleteEntry() {
      this.$store.commit('SHOW_MODAL', { modal: DeleteEntryModal, props: { mode: 'current' } })
    },
    prev() {
      this.$store.commit('entries/PREV_ENTRY')
    },
    next() {
      this.$store.commit('entries/NEXT_ENTRY')
    }
  }
}
</script>

<style lang="stylus" scoped>
ol
  display flex
  flex-direction row
  justify-content center
  gap 15px

#download-entry
  display none
</style>