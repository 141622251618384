<template>
  <div id="toolbar">
    <BreadCrumbs/>
    <component :is="$route.meta.toolbar"/>
  </div>
</template>

<script>
import BreadCrumbs from '@/components/BreadCrumbs'
import { mapState } from 'vuex'

export default {
  name: 'ToolBar',
  components: { BreadCrumbs },
  computed: mapState({
    currentBoard: s => s.boards.currentBoard,
    currentEntry: s => s.entries.currentEntry
  })
}
</script>

<style lang="stylus">
#toolbar
  display flex
  flex-direction row
  padding 10px 20px
  align-items center
  justify-content space-between
  border-bottom 1px solid #ccc
</style>